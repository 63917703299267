import { createSelector } from 'reselect';
import { selectInstoreIsEnabled, selectHasNoRewardsEnabled } from 'core/selectors/app';
import { selectGspConfig } from 'core/selectors/services';
import config from '../configs';

export const selectQuickSearchConfig = createSelector(
  [
    state => state.hybridQuickSearch.config,
    (state, customConfig) => customConfig,
    selectGspConfig,
    selectInstoreIsEnabled,
  ],
  // Combine configs, so can be overwritten at component level
  (initialConfig, customConfig, gsp, instoreIsEnabled) => ({
    ...initialConfig,
    shouldUseGsp: gsp.isEnabled,
    instoreIsEnabled,
    ...customConfig,
  }),
);

// GSP products
export const selectQuickSearchGspProducts = state => state.hybridQuickSearch.gspProducts;
export const selectQuickSearchGspProductsMatches =
  createSelector(selectQuickSearchGspProducts, gspProducts => gspProducts.placements);
export const selectQuickSearchGspProductsisLoaded =
  createSelector(selectQuickSearchGspProducts, gspProducts => gspProducts.isLoaded);
  export const selectQuickSearchGspProductsisLoading =
  createSelector(selectQuickSearchGspProducts, gspProducts => gspProducts.isLoading);

// GSP merchants
export const selectQuickSearchGspMerchants = state => state.hybridQuickSearch.gspMerchants;
export const selectQuickSearchGspMerchantsMatches =
  createSelector(selectQuickSearchGspMerchants, gspMerchants => gspMerchants.placements);
export const selectQuickSearchGspMerchantsisLoaded =
  createSelector(selectQuickSearchGspMerchants, gspMerchants => gspMerchants.isLoaded);
  export const selectQuickSearchGspMerchantsisLoading =
  createSelector(selectQuickSearchGspMerchants, gspMerchants => gspMerchants.isLoading);

// Merchants
export const selectQuickSearchMerchants = state => state.hybridQuickSearch.merchants;
export const selectQuickSearchMerchantsIsLoading =
  createSelector(selectQuickSearchMerchants, quickSearchMerchants => quickSearchMerchants.isLoading);
export const selectQuickSearchMerchantsIsLoaded =
  createSelector(selectQuickSearchMerchants, quickSearchMerchants => quickSearchMerchants.isLoaded);
export const selectQuickSearchMerchantsPlacements =
  createSelector(selectQuickSearchMerchants, quickSearchMerchants => quickSearchMerchants.merchants);

export const selectQuickSearchMatchesAll = (state, keywords, config = {}) => {
  if (keywords.length >= config.minChars) {
    const merchants = selectQuickSearchMerchantsPlacements(state);
    return config.shouldUseGsp
      ? findQuickSearchGspMatches(merchants, keywords, state) : findQuickSearchMatches(merchants, keywords, state);
  }

  return {
    synonyms: [],
    instore: [],
    online: [],
    exact: {},
  };
};

// Get all matches and slice each type of match to the configured max size
export const selectQuickSearchMatches = (state, keywords, config = {}) => {
  const matches = selectQuickSearchMatchesAll(state, keywords, config);
  const { maxMatches } = selectQuickSearchConfig(state, config);
  const synonyms = matches.synonyms.slice(0, maxMatches);
  const online = matches.online.slice(0, maxMatches);
  const instore = matches.instore.slice(0, maxMatches);
  const { exact } = matches;

  return {
    synonyms,
    instore,
    online,
    exact,
  };
};

export const selectMatchesLength = (matches, instoreIsEnabled) => (
  matches.synonyms.length + instoreIsEnabled ? matches.instore.length : 0 + matches.online.length
);

export const selectQuickSearchMatchesAllLength = (state, keywords, config) => {
  const { instoreIsEnabled } = config;
  const matches = selectQuickSearchMatchesAll(state, keywords);
  return selectMatchesLength(matches, instoreIsEnabled);
};

export const selectQuickSearchMatchesLength = (state, keywords, config) => {
  const { instoreIsEnabled } = config;
  const matches = selectQuickSearchMatches(state, keywords, config);
  return selectMatchesLength(matches, instoreIsEnabled);
};

export const selectExactQuickSearchMatchName = (state, keywords, config) => {
  const { exact: { name } } = selectQuickSearchMatches(state, keywords, config);

  return name;
};

export const selectExactQuickSearchMatchSimilarStores = (state, keywords, config) => {
  const quickSearchMatches = selectQuickSearchMatches(state, keywords, config);

  const { exact: { relatedActiveMerchants } } = quickSearchMatches;

  return (relatedActiveMerchants && relatedActiveMerchants.length) ? relatedActiveMerchants : null;
};

export const selectSynonymsQuickSearchMatches = (state, keywords, config) => (
  selectQuickSearchMatches(state, keywords, config).synonyms
);

export const selectOnlineQuickSearchMatches = (state, keywords, config) => (
  selectQuickSearchMatches(state, keywords, config).online
);

export const selectInstoreQuickSearchMatches = createSelector(
  [
    state => state,
    (state, keywords) => keywords,
    (state, keywords, config) => config,
    selectInstoreIsEnabled,
  ],
  (state, keywords, config, isInstoreEnabled) => (
    isInstoreEnabled ? selectQuickSearchMatches(state, keywords, config).instore : []
  ),
);

export const selectQuickSearchHasMatches = (state, keywords, config) => {
  const synonymsMatches = selectSynonymsQuickSearchMatches(state, keywords, config);
  const onlineMatches = selectOnlineQuickSearchMatches(state, keywords, config);
  const instoreMatches = selectInstoreQuickSearchMatches(state, keywords, config);
  return onlineMatches.length > 0 || instoreMatches.length > 0 || synonymsMatches.length > 0;
};

// Finds matches within the merchant list based on inputed keywords
function findQuickSearchMatches(merchants, keywords, state) {
  const { maxAnchorFront } = config;
  const query = keywords.replace(/[^a-zA-Z\u00C0-\u00FF0-9\s\-.'&"!$@+]/g, '').toLowerCase();
  const regEx = query.length < maxAnchorFront
    ? new RegExp(`^(${query})`, 'i') : new RegExp(`(${query})`, 'i');
  const hasNoRewardsEnabled = selectHasNoRewardsEnabled(state);

  return merchants.reduce((matches, merchant) => {
    if (merchant.type === 'Suspended Merchant' || (!hasNoRewardsEnabled && merchant.type === 'Deactivated Merchant')) {
      return matches;
    }

    if (query === (merchant.name || merchant.nameLatinized).toLowerCase()) {
      // eslint-disable-next-line no-param-reassign
      matches.exact = merchant;
    }

    const matchBySynonyms = merchant.synonyms && merchant.synonyms.split(',').indexOf(query) > -1;
    if (matchBySynonyms) {
      matches.synonyms.push({
        merchant,
      });
    } else {
      const nameMatch = regEx.exec(merchant.name) || (merchant.nameLatinized
        ? regEx.exec(merchant.nameLatinized) : false);
      // MOP-494: filter out instore merchants that don't have any active offers
      if (nameMatch && (!merchant.isInstore || (merchant.offers && merchant.offers.length > 0))) {
        const matchStartIndex = nameMatch && nameMatch.index;
        const matchText = merchant.name.substr(matchStartIndex, query.length);
        if (merchant.isInstore) {
          matches.instore.push({
            merchant,
            matchText,
          });
        } else {
          matches.online.push({
            merchant,
            matchText,
          });
        }
      }
    }

    return matches;
  }, {
    synonyms: [],
    instore: [],
    online: [],
    exact: {},
  });
}

// Finds matches within the merchant list based on inputed keywords using GSP suggestions
function findQuickSearchGspMatches(merchants, keywords, state) {
  const suggestionsGSP = selectQuickSearchGspMerchantsMatches(state);
  const { maxAnchorFront } = config;
  const query = keywords.replace(/[^a-zA-Z\u00C0-\u00FF0-9\s\-.'&"!$@+]/g, '').toLowerCase();
  const regEx = query.length < maxAnchorFront
    ? new RegExp(`^(${query})`, 'i') : new RegExp(`(${query})`, 'i');
  const hasNoRewardsEnabled = selectHasNoRewardsEnabled(state);
  const stores = suggestionsGSP.reduce((storeIds, store) => {
      storeIds.set(parseInt(store.storeId, 10), null);

      return storeIds;
  }, new Map());

  if (!merchants.length || !stores) {
    return {
      synonyms: [],
      instore: [],
      online: [],
      exact: {},
    };
  }

  merchants.forEach((merchant) => {
    if (Array.from(stores.keys()).includes(merchant.id) || merchant.isInstore) {
      stores.set(merchant.id, merchant);
    }
  });

  const gspMerchants = Array.from(stores.values()).reduce((matches, merchant) => {
    if (!merchant) {
      return matches;
    }

    if (merchant.type === 'Suspended Merchant' || (!hasNoRewardsEnabled && merchant.type === 'Deactivated Merchant')) {
      return matches;
    }

    if (query === (merchant.name || merchant.nameLatinized).toLowerCase()) {
      // eslint-disable-next-line no-param-reassign
      matches.exact = merchant;
    }
    const nameMatch = regEx.exec(merchant.name) || (merchant.nameLatinized
      ? regEx.exec(merchant.nameLatinized) : false);
    const matchStartIndex = nameMatch && nameMatch.index;
    const matchText = nameMatch ? merchant.name.substr(matchStartIndex, query.length) : '';

    if (nameMatch && merchant.isInstore && merchant.offers && merchant.offers.length > 0) {
      matches.instore.push({
        merchant,
        matchText,
      });
    }

    if (merchant.type === 'online' || (hasNoRewardsEnabled && merchant.type === 'Deactivated Merchant')) {
      matches.online.push({
        merchant,
        matchText,
      });
    }

    return matches;
  }, {
    synonyms: [],
    instore: [],
    online: [],
    exact: {},
  });

  return gspMerchants;
}
