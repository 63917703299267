import md5 from 'md5';
import dayjs from 'core/utils/dayjs';
import { get, merge } from 'lodash';
import { isErrorResponse } from 'core/utils/apis/base';
import { selectIsApiCacheEnabled } from 'core/selectors/env';
import { LOCAL_STORAGE, SESSION_STORAGE, CACHE_STORAGE } from 'core/utils/cache/base';
import LocalStorageCache from 'core/utils/cache/localStorage';
import SessionStorageCache from 'core/utils/cache/sessionStorage';
import { cacheStorageCache } from 'core/utils/cache/cacheStorage';

/** @type {LocalStorageCache} */
const localStorageCache = new LocalStorageCache();
/** @type {SessionStorageCache} */
const sessionStorageCache = new SessionStorageCache();

/**
 *
 * @param action
 * @return {*}
 */
function getCacheKey(action) {
  const { options, ...limitedAction } = action; // extract cache options from cache key calculation
  const { cache: { isPublishedData, recordKey } } = options;

  // Return cache record key if it is hardcoded as part of an action options (e.g. member/favorites endpoint)
  if (recordKey) return recordKey;

  const cachedPublishId = localStorageCache.getItem('publishId');
  let cacheKey = `${action.type}-${md5(JSON.stringify(limitedAction))}`;

  if (isPublishedData && cachedPublishId) {
    cacheKey = `${cacheKey}-##pubID:${cachedPublishId}`;
  }

  return cacheKey;
}

/**
 * @param {object} action
 * @return {*}
 */
async function getCachedResponse(action) {
  const { cache: { isEnabled = false, type = LOCAL_STORAGE } = {} } = action.options;

  if (!isEnabled) {
    return false;
  }

  const cacheKey = getCacheKey(action);

  switch (type) {
    case CACHE_STORAGE: {
      return cacheStorageCache.getItem(cacheKey);
    }
    case SESSION_STORAGE: {
      return sessionStorageCache.getItem(cacheKey);
    }
    default:
      return localStorageCache.getItem(cacheKey);
  }
}

function isApiCacheEnabledCheck({ action, state, enableCache }) {
  const isEnabledPerAppEnvOptions = selectIsApiCacheEnabled(state);
  const isEnabledPerCallOptions = enableCache;
  const isEnabledPerActionOptions = get(action, 'options.cache.isEnabled');

  if (!isEnabledPerAppEnvOptions) {
    return false;
  }

  if (typeof isEnabledPerCallOptions !== 'undefined') {
    return !!isEnabledPerCallOptions;
  }

  if (typeof isEnabledPerActionOptions !== 'undefined') {
    return !!isEnabledPerActionOptions;
  }

  return false;
}

/**
 * @param {object} action
 * @param {object} response
 */
export async function setCachedResponse(action, response) {
  const expirationDate =
    dayjs().startOf('hour').add(3570, 'seconds').valueOf(); // 59 minutes + 30 seconds from start of hour

  const {
    cache: {
      isEnabled = false,
      type = LOCAL_STORAGE,
      expirationTimestamp = expirationDate,
      persist = false,
    } = {},
  } = action.options;

  // if sort_by is 'random', disable cache anyway
  if (isEnabled && action.params?.sort_by !== 'random') {
    const cacheKey = getCacheKey(action);

    if (response.data && response.data.metadata) {
      const { metadata } = response.data;

      // Inject cache info to metadata
      metadata.isCached = true;
      metadata.cacheType = type;
      metadata.cacheExpires = expirationTimestamp;
      metadata.cachePersists = persist;
    }

    const cacheOptions = {
      expirationTimestamp,
      persist,
    };

    const responsePayload = {
      data: response.data,
    };

    switch (type) {
      case CACHE_STORAGE: {
        await cacheStorageCache.setItem(cacheKey, responsePayload, cacheOptions);
        break;
      }
      case SESSION_STORAGE: {
        sessionStorageCache.setItem(cacheKey, responsePayload, cacheOptions);
        break;
      }
      default:
        localStorageCache.setItem(cacheKey, responsePayload, cacheOptions);
        break;
    }
  }
}

/* eslint-disable object-curly-newline */
export default class BaseApiRequestor {
  /**
   * 'action.type' prop contains the api action name
   * each api endpoint has a different response structure
   * and a separate parser function for it which mapped in this object
   */
  responseParsersForAPI = {};

  getDefaultResponseParserForActionByType(action) {
    const { type, params: { type: typeInfo = '' } } = action;

    if (typeInfo === 'instore') {
      return !!type && this.responseParsersForAPI[type + typeInfo];
    }

    return !!type && this.responseParsersForAPI[type];
  }

  parseResponse({ response, parser, action, state }) {
    // If custom parser function is passed then use it
    // otherwise look up for the default API parsers based on action type
    const responseParser = parser || this.getDefaultResponseParserForActionByType(action);
    return responseParser ? responseParser(response, state) : response;
  }

  /**
   * Make API call asynchronously using a passed 'caller' method and optios stored inside the 'action' object,
   * return a response json (or the error message) wrapped in an axios response object
   * @param {object} caller
   * @param {array.<string>} requestArgList
   * @param {object} action
   * @return {object} axios response structure
   */
  /* eslint-disable-next-line class-methods-use-this */
  async makeAPICall({ caller, requestArgList = [], action = {} }) {
    const { params, data } = action;
    const requestArgumnets = requestArgList.map(arg => data[arg]) || [];
    try {
      const response = requestArgumnets.length > 0 ? await caller(...requestArgumnets, params) : await caller(params);
      return response;
    } catch (error) {
      return { status: 500, statusText: error.toString() };
    }
  }

  /**
   * Get API response asynchronously. Returns a response json (or the error message) wrapped in an axios response object
   * The response could be received from the canche (if available per org config) instead of the real API call:
   * - by setting the related flag in the action (set the component's config option: api.options.cache.isEnabled: true)
   * - by passing the 'enableCache = true' flag argument to force caching not depending on the action's flag
   * The response could be parsed if the 'shouldParseResponse' flag is set to 'true'. The parser function must be set:
   *  - by default is set in the 'responseParsersForAPI' map which should have a key maching the passed action's 'type'
   *  - the custom function could be passed as 'parser' argument
   * @param {object} caller
   * @param {object} parser
   * @param {boolean} shouldParseResponse
   * @param {array.<string>} requestArgList
   * @param {object} action
   * @param {object} state
   * @return {object} axios response structure
   */
  async getAPIResponse({ caller, parser, shouldParseResponse = false, enableCache, requestArgList, action, state }) {
    const isApiCacheEnabled = isApiCacheEnabledCheck({ action, state, enableCache });
    const cachedResponse = isApiCacheEnabled && await getCachedResponse(action);

    let response;

    if (cachedResponse) {
      response = cachedResponse;
    } else {
      response = await this.makeAPICall({ caller, requestArgList, action });
      if (isApiCacheEnabled && !isErrorResponse(response)) {
        await setCachedResponse(action, response);
      }
    }

    if (shouldParseResponse && !isErrorResponse(response)) {
      // The 'response' object returned by 'makeAPICall' method has an axios response structure.
      // So the actual json data returned from API is stored in the 'response.data.response' property
      const parsedResponseData = this.parseResponse({
        response: get(response, 'data.response'),
        parser,
        action,
        state,
      });

      // Merge actual json data returned from API with parsedResponseData
      merge(response, { data: { response: parsedResponseData } });
    }

    return response;
  }
}
