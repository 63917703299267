import { merge } from 'lodash';
import parseClickUrl from './commonParsers/clickUrlParser';
import parseRebate from './commonParsers/rebateParser';
import {
  parseOfferDateProperties, checkIfOfferHasCountdown, getOfferCountdownStopDate,
} from './commonParsers/offerDateParser';
import assetsParser from './commonParsers/assetsParser';

export default (response, state) =>
  response.map(offer => {
    const { highlightedMerchantTierId } = offer;

    if (highlightedMerchantTierId && offer.merchant.tiers) {
      offer.merchant.tiers.forEach((tier) => {
        if (tier.tierId === highlightedMerchantTierId) {
          offer.merchant.rebate.suffixTierName = tier.name;
        }
      });
    }

    return merge(offer, parseOfferDateProperties(offer, state), {
      assets: assetsParser(offer.assets),
      clickUrl: parseClickUrl(offer.clickUrl, state),
      hasCountdown: checkIfOfferHasCountdown(offer),
      countdownStopDatetime: getOfferCountdownStopDate(offer),
      merchant: offer.merchant && {
        clickUrl: parseClickUrl(offer.merchant.clickUrl, state),
        rebate: parseRebate(offer.merchant.rebate, offer.merchant.tiers, state),
      },
    });
  });
