import { createSelector } from 'reselect';

export const selectConfig = state => state.app;
export const selectApp = createSelector(selectConfig, config => config.app);

// Org selectors
export const selectOrg = createSelector(selectApp, app => app.org);
export const selectOrgId = createSelector(selectOrg, org => org.id);

// Brand selectors
export const selectBrand = createSelector(selectApp, app => app.brand);
export const selectBrandId = createSelector(selectBrand, brand => brand.id);
export const selectBrandName = createSelector(selectBrand, brand => brand.name);
export const selectBrandDisplayName = createSelector(selectBrand, brand => brand.displayName);
export const selectBrandGdprDisplayName = createSelector(selectBrand, brand => brand.gdprDisplayName);

// Page selectors
export const selectPage = createSelector(selectApp, app => app.page);
export const selectPageId = createSelector(selectPage, page => page.id);
export const selectPageName = createSelector(selectPage, page => page.name);

// Features selectors
export const selectFeatures = createSelector(selectApp, app => app.features);
export const selectInstore = createSelector(selectFeatures, features => features.instore);
export const selectInstoreIsEnabled = createSelector(selectInstore, instore => instore.isEnabled);
export const selectInstoreIsMarketingEnabled = createSelector(selectInstore, instore => instore.isMarketingEnabled);
export const selectInstoreIsNavNewEnabled = createSelector(selectInstore, instore => instore.isNavNewEnabled);
export const selectInstoreIsSMSFeatureEnabled = createSelector(selectInstore, instore => instore.isSMSFeatureEnabled);
export const selectInstoreIsSMSAccountSettingsEnabled =
  createSelector(selectInstore, instore => instore.isSMSAccountSettingsEnabled);
export const selectSMSShortCode = createSelector(selectInstore, instore => instore.smsShortCode);
export const selectReferral = createSelector(selectFeatures, features => features.referral);
export const selectReferralStartDate = createSelector(selectReferral, referral => referral.startDate);
export const selectReferralEndDate = createSelector(selectReferral, referral => referral.endDate);
export const selectExtraRewards = createSelector(selectFeatures, features => features.extraRewards);
export const selectExtraRewardsPage = createSelector(selectExtraRewards, extraRewards => extraRewards.page);
export const selectExtraRewardsFeaturedEnabled =
  createSelector(selectExtraRewards, extraRewards => extraRewards.isFeaturedExtraRewardsEnabled);
export const selectExtraRewardsFavoritesEnabled =
  createSelector(selectExtraRewards, extraRewards => extraRewards.isFeaturedExtraRewardsFavoritesEnabled);
export const selectStoreCount = createSelector(selectFeatures, features => features.storeCount);
export const selectHasNoRewardsEnabled = createSelector(selectFeatures, features => features.hasNoRewardsEnabled);
export const selectEnableAdBlockWarning = createSelector(selectFeatures, features => features.enableAdBlockWarning);
export const selectMhpMarketingSectionEnabled =
  createSelector(selectFeatures, features => features.hasMhpMarketingSectionEnabled);
export const selectEnableRecentStoresFlyout =
  createSelector(selectFeatures, features => features.enableRecentStoresFlyout);
export const selectRecentStoresFlyoutCacheKey =
  createSelector(selectFeatures, features => features.recentStoresFlyoutCacheKey);
export const selectHasEmailOptInPopupEnabled =
  createSelector(selectFeatures, features => features.hasEmailOptInPopupEnabled);
export const selectOnboarding = createSelector(selectFeatures, features => features.onboarding);
export const selectOnboardingIsEnabled = createSelector(selectOnboarding, onboarding => onboarding.isEnabled);
export const selectOnboardingSuppressedPages =
  createSelector(selectOnboarding, onboarding => onboarding.suppressedPages || []);
export const selectOnboardingStepWeighting = createSelector(selectOnboarding, onboarding => onboarding.stepWeighting);
export const selectToolbarConfig = createSelector(selectFeatures, features => features.toolbarConfig) || {};
export const selectDataPrivacy = createSelector(selectFeatures, features => features.dataPrivacy);
export const selectDataPrivacyPageEnabled = createSelector(selectDataPrivacy, dataPrivacy => dataPrivacy.isEnabled);
export const selectRewardCount = createSelector(selectFeatures, features => features.rewardCount);
export const selectPrivacyLinkEnabledFeature = createSelector(selectFeatures, features => features.privacyNoticeLink);
export const selectPrivacyLinkEnabled =
  createSelector(selectPrivacyLinkEnabledFeature, privacyNoticeLink => privacyNoticeLink.isEnabled);
export const selectGoogleMapApiKey =
  createSelector(selectFeatures, features => features.googleMapApiKey);
export const selectHasRedirectUsersToHIWEnabled =
  createSelector(selectFeatures, features => features.hasRedirectUsersToHIWEnabled);
export const selectSaveAndEarnLetterboxEnabled =
  createSelector(selectFeatures, features => features.hasSaveAndEarnLetterboxEnabled);

// Miscellaneous selectors
export const selectSource = createSelector(selectApp, app => app.source);
export const selectImageRootUrl = createSelector(selectApp, app => app.imageRootUrl);
export const selectLoginUrl = createSelector(selectApp, app => app.loginUrl);
export const selectLogoutUrl = createSelector(selectApp, app => app.logoutUrl);
export const selectRegistrationUrl = createSelector(selectApp, app => app.registrationUrl);
export const selectSiteUrl = createSelector(selectApp, app => app.siteUrl);
export const selectXDotUrl = createSelector(selectApp, app => app.xDotUrl);
export const selectClickUrlPrefix = createSelector(selectApp, app => app.clickUrlPrefix);
export const selectProductImageUrlPrefix = createSelector(selectApp, app => app.productImageUrlPrefix);
export const selectFormSecurityToken = createSelector(selectApp, app => app.formSecurityToken);
export const selectClientHostedDPSRedirectURL = createSelector(selectApp, app => app.clientHostedDPSRedirectURL);
export const selectTermsAndConditionsURL = createSelector(selectApp, app => app.termsAndConditionsURL);
export const selectPrivacyPolicyUrl = createSelector(selectApp, app => app.privacyPolicyUrl);
export const selectBrandUrl = createSelector(selectApp, app => app.brandUrl);
