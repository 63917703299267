import { merge } from 'lodash';
import dayjs from 'core/utils/dayjs';

import * as actions from 'core/actions/bonus';
import { selectPreviewDateTime } from 'core/selectors/env';
import responseParsers from './responseParsers';
import BaseApiRequestor from './baseRequestor';

const extendActionWithDefaultAPIParameters = (action, state) => {
  const previewDateTime = selectPreviewDateTime(state);
  return merge({}, action, {
    params: {
      ...previewDateTime && { preview_datetime: dayjs(previewDateTime).format('YYYY-MM-DD HH:mm:ss') },
    },
  });
};

export class BonusRequestor extends BaseApiRequestor {
  responseParsersForAPI = {
    [actions.BONUS_GET_INFORMATION]: responseParsers.bonusParser,
    [actions.BONUS_GET_INFORMATION_MEMBER_RELATED]: responseParsers.bonusParser,
  };

  async getAPIResponse({
    caller, parser, shouldParseResponse = false, enableCache, requestArgList, action, state,
  }) {
    const extendedAction = extendActionWithDefaultAPIParameters(action, state);
    return super.getAPIResponse({
      caller, parser, shouldParseResponse, enableCache, requestArgList, action: extendedAction, state,
    });
  }
}

export default new BonusRequestor();
