import { uniq } from 'lodash';
import {
  CLICK_HISTORY_GET_SUCCESS, CLICK_HISTORY_GET_FAILURE, CLICK_HISTORY_FROM_CACHE_GET_SUCCESS,
} from '../actions/types';
import config from '../configs';

const { clickHistoryConfig } = config;

const initialState = {
  config: clickHistoryConfig,
  gmids: [],
  isLoading: false,
  isLoaded: false,
};

// Generate array with globalMerchantIds and filter duplicates
const processGmids = (response) => {
  const recentGmids = response.map(({ globalMerchantId }) => globalMerchantId);

  return uniq(recentGmids);
};

const clickHistory = (state = initialState, action) => {
  switch (action.type) {
    case CLICK_HISTORY_FROM_CACHE_GET_SUCCESS: {
      return {
        ...state,
        gmids: action.gmids,
        isLoading: false,
        isLoaded: true,
      };
    }
    case CLICK_HISTORY_GET_SUCCESS: {
      return {
        ...state,
        gmids: processGmids(action.payload.response),
        isLoading: false,
        isLoaded: true,
      };
    }
    case CLICK_HISTORY_GET_FAILURE: {
      return {
        ...state,
        gmids: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === CLICK_HISTORY_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default clickHistory;
