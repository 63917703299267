import { unionBy } from 'lodash';
import coreGaActionEvents, { coreGaClickAndHoverEvents } from 'core/utils/analytics/ga-events';
import {
  signInNagLargeClickAndHoverEvents,
  signInNagLargeGaActionEvents,
} from 'org/modules/SignInNagLarge/analytics';
import { navigationTOFGaActionEvents } from 'org/modules/NavigationTOF/analytics';

import { signInNagSmallClickAndHoverEvents } from 'org/modules/SignInNagSmall/analytics';
import { headerClickAndHoverEvents } from 'org/modules/Header/analytics';
import {
  homeValueBannerGaActionEvents,
  homeValueBannerClickEvents,
} from 'org/modules/HomeValueBanner/analytics';

import {
  howItWorksGaActionEvents,
  howItWorksClickEvents,
} from 'org/modules/ShopWifi/components/ShopWifiHiw/analytics';

const gaActionEvents = {
  ...coreGaActionEvents,
  ...signInNagLargeGaActionEvents,
  ...navigationTOFGaActionEvents,
  ...homeValueBannerGaActionEvents,
  ...howItWorksGaActionEvents,
};

const orgGaClickAndHoverEvents = [
  ...signInNagLargeClickAndHoverEvents,
  ...signInNagSmallClickAndHoverEvents,
  ...headerClickAndHoverEvents,
  ...homeValueBannerClickEvents,
  ...howItWorksClickEvents,
];

/**
 * Combine org-specific and core click-and-hover events. Please note that the
 * unionBy lodash method will override a core event if they have the same
 * @type {EventTrackerList}
 */
export const gaClickAndHoverEvents = unionBy(orgGaClickAndHoverEvents, coreGaClickAndHoverEvents, 'selector');

export default gaActionEvents;
