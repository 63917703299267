import {
  PRODUCT_OVERLAY_LINK_GET_SUCCESS,
  PRODUCT_OVERLAY_LINK_GET_FAILURE,
  PRODUCT_OVERLAY_LINK_CLEAR,
} from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  product: {},
};

const productOverlayLink = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_OVERLAY_LINK_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        product: action.payload.response[0],
      };
    }
    case PRODUCT_OVERLAY_LINK_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    case PRODUCT_OVERLAY_LINK_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default productOverlayLink;
