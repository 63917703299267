import { useEffect } from 'react';
import keyCodes from 'core/utils/helpers/keyCodes';

export const useHandleKeyboardNavigation = (quickSearchResultsRef, selectorString) => {
  useEffect(() => {
    const quickSearchResultsElem = quickSearchResultsRef.current;
    const matchElements = quickSearchResultsElem?.querySelectorAll(selectorString);

    const getFocusedIndex = (matchElements) => {
      let focusedIndex = 0;

      [...matchElements].some((element, index) => {
        if (element === document.activeElement) {
          focusedIndex = index;
          return true;
        }

        return false;
      });

      return focusedIndex;
    };

    if (!matchElements) {
      return false;
    }

    const handleKeydownListener = (e) => {
      const pressedKey = e.keyCode;

      switch (pressedKey) {
        case keyCodes.ARW_DOWN: {
          e.preventDefault();
          let focusedIndex = getFocusedIndex(matchElements);
          focusedIndex = focusedIndex >= matchElements.length - 1 ? 0 : focusedIndex + 1;
          matchElements[focusedIndex].focus();
          break;
        }
        case keyCodes.ARW_UP: {
          e.preventDefault();
          let focusedIndex = getFocusedIndex(matchElements);
          focusedIndex = focusedIndex <= 0 ? matchElements.length - 1 : focusedIndex - 1;
          matchElements[focusedIndex].focus();
          break;
        }
        default:
          break;
      }
    };

    quickSearchResultsElem?.addEventListener('keydown', handleKeydownListener);
    return () => quickSearchResultsElem?.removeEventListener('keydown', handleKeydownListener);
  });
};
