import { merge, get } from 'lodash';
import parseClickUrl from './commonParsers/clickUrlParser';
import parseRebate from './commonParsers/rebateParser';

export default (response = [], state) =>
  response.map((bonus) => {
    const bonusMerchant = get(bonus, 'offers[0].qualifyingMerchants.merchants', [])[0];
    return bonusMerchant ? merge(bonus, {
      offers: [
        {
          qualifyingMerchants: {
            merchants: [
              {
                id: bonusMerchant.globalMerchantId,
                clickUrl: parseClickUrl(bonusMerchant.clickUrl, state),
                rebate: parseRebate(bonusMerchant.rebate, [], state),
              },
            ],
          },
        },
      ],
    }) : bonus;
  });
