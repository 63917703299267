import { SALE_FILTER } from 'core/modules/ProductSearchResults/utils/keys';
import {
  PRODUCT_SEARCH_RESULTS_CLEAR,
  PRODUCT_SEARCH_RESULTS_GET_FAILURE,
  PRODUCT_SEARCH_RESULTS_GET_SUCCESS,
  PRODUCT_SEARCH_RESULTS_FILTER_GET_SUCCESS,
  PRODUCT_SEARCH_RESULTS_FILTER_GET_FAILURE,
  PRODUCT_SEARCH_RESULTS_UPDATE_GET_SUCCESS,
  PRODUCT_SEARCH_RESULTS_UPDATE_GET_FAILURE,
} from '../actions/types';
import { productSearchResultsConfig as config } from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  isFiltering: false,
  isFiltered: false,
  isUpdating: false,
  isUpdated: false,
  payload: {},
  initialFacets: [],
};

const handleInitialFacets = (payload) => {
  const facets = payload?.metadata?.facets || [];

  // API doesn't return sale filter facet in metadata so adding it on FE
  facets.push({ name: SALE_FILTER, values: [] });

  return facets.map((facet) => handleFacet(facet));

   // 'Price' facet returns `null` for every value id, so replacing it with other unique value
  function handleFacet(facet) {
    const values = facet.values.map(item => ({
      ...item,
      id: item.id || item.value,
    }));

    return ({
      ...facet,
      values,
    });
  }
};

// There is and issue when API could return same product for API calls with a mutually exclusive `offset` request param.
// So we take a offset value and product id to generate a unique key and use it for rendering React product list.
const addPlacementKey = (placements, idPrefix) => (
  placements.map((placement) => ({
      ...placement,
      key: `${idPrefix}_${placement.id}`,
  }))
);

const productSearchResults = (state = initialState, action) => {
  switch (action.type) {
    // load initial content (on page load)
    case PRODUCT_SEARCH_RESULTS_GET_SUCCESS: {
      action.payload.response = addPlacementKey(action.payload.response, action.payload.metadata.offset);

      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        payload: action.payload,
        initialFacets: handleInitialFacets(action.payload),
      };
    }
    case PRODUCT_SEARCH_RESULTS_CLEAR:
    case PRODUCT_SEARCH_RESULTS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        payload: {
          metadata: {
            total: 0,
            facets: [],
          },
          response: [],
        },
      };
    }

    // filter or sort content
    case PRODUCT_SEARCH_RESULTS_FILTER_GET_SUCCESS: {
      action.payload.response = addPlacementKey(action.payload.response, action.payload.metadata.offset);

      return {
        ...state,
        isFiltering: false,
        isFiltered: true,
        payload: action.payload,
      };
    }
    case PRODUCT_SEARCH_RESULTS_FILTER_GET_FAILURE: {
      return {
        ...state,
        isFiltering: false,
        isFiltered: true,
        payload: {
          metadata: {
            total: 0,
            facets: [],
          },
          response: [],
        },
      };
    }

    // update content (load more on see more click)
    case PRODUCT_SEARCH_RESULTS_UPDATE_GET_SUCCESS: {
      const response = addPlacementKey(action.payload.response, action.payload.metadata.offset);
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        payload: {
          metadata: action.payload.metadata,
          response: [
            ...state.payload.response,
            ...response,
          ],
        },
      };
    }
    case PRODUCT_SEARCH_RESULTS_UPDATE_GET_FAILURE: {
      return {
        ...state,
        isFiltering: false,
        isFiltered: true,
      };
    }

    default: {
      const { options = {} } = action;
      if (options.success === PRODUCT_SEARCH_RESULTS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
      if (options.success === PRODUCT_SEARCH_RESULTS_FILTER_GET_SUCCESS) {
        return {
          ...state,
          isFiltering: true,
          isFiltered: false,
        };
      }
      if (options.success === PRODUCT_SEARCH_RESULTS_UPDATE_GET_SUCCESS) {
        return {
          ...state,
          isUpdating: true,
          isUpdated: false,
        };
      }
    }
  }

  return state;
};

export default productSearchResults;
