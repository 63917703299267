import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { headerHolidayImage as orgConfig } from 'org/configs/modules.config';

import {
  HEADER_HOLIDAY_IMAGE_GET_FAILURE,
  HEADER_HOLIDAY_IMAGE_GET_SUCCESS,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    shouldShowHolidayImage: true,
    api: {
      options: {
        success: HEADER_HOLIDAY_IMAGE_GET_SUCCESS,
        failure: HEADER_HOLIDAY_IMAGE_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
      params: {
        content_group_id: 22175,
        fields: 'assets.image',
      },
    },
  },
  sweeps: {
    shouldShowHolidayImage: false,
  },
  sweepsrules: {
    shouldShowHolidayImage: false,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
