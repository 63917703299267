import { isEmpty } from 'lodash';
import {
  FEATURED_DEALS_HEADING_GET_FAILURE,
  FEATURED_DEALS_HEADING_GET_SUCCESS,
  FEATURED_DEALS_HEADING_FILL,
} from '../actions/types';

const initialState = {
  isLoading: false,
  isLoaded: false,
  data: {},
};

const handleResponse = (response) => {
  if (isEmpty(response)) {
    return null;
  }

  const { assets: { shortSummaryText, callToActionText }, clickUrl } = response[0];

  return {
    viewAllLinkText: callToActionText,
    sectionTitle: shortSummaryText,
    clickUrl,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FEATURED_DEALS_HEADING_FILL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.payload,
      };
    }
    case FEATURED_DEALS_HEADING_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: handleResponse(action.payload.response),
      };
    }
    case FEATURED_DEALS_HEADING_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: [],
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === FEATURED_DEALS_HEADING_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};
