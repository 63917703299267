export const MODAL_TYPE_NOTIFICATION = 'mt_notification';
export const MODAL_TYPE_CONFIRMATION = 'mt_confirmation';
export const MODAL_TYPE_MERCHANT_OFFER = 'mt_merchantOffer';
export const MODAL_TYPE_MERCHANT = 'mt_merchant';
export const MODAL_TYPE_FEATURED_STORES = 'mt_featuredStores';
export const MODAL_TYPE_FIRST_FAVORITE = 'mt_firstFavorite';
export const MODAL_TYPE_INSTORE_HIW = 'mt_instoreHiw';
export const MODAL_TYPE_EMAIL_OPT_IN_SUBSCRIBE = 'MODAL_TYPE_EMAIL_OPT_IN_SUBSCRIBE';
export const MODAL_TYPE_EMAIL_OPT_IN_SUCCESS = 'MODAL_TYPE_EMAIL_OPT_IN_SUCCESS';
export const MODAL_TYPE_DELETED_MEMBER_MODAL = 'mt_deletedMember';
export const MODAL_TYPE_ORDER_INQUIRY = 'mt_orderInquiry';
export const MODAL_TYPE_INSTORE_OFFER = 'mt_instoreOffer';
export const MODAL_TYPE_NEAR_ME_MAP = 'mt_allStoresMap';
export const MODAL_TYPE_INSTORE_MANAGE_CARDS = 'mt_instoreManageCards';
export const MODAL_TYPE_INSTORE_ADD_NEW_CARD = 'mt_instoreAddNewCard';
export const MODAL_TYPE_INSTORE_SMS_NUMBER = 'mt_instoreSMSNumber';
export const MODAL_TYPE_INSTORE_MULTI_LINK = 'mt_instoreMultiLink';
export const MODAL_TYPE_HIW_VIDEO = 'mt_hiwVideo';
export const MODAL_TYPE_TERMS_AND_CONDITIONS = 'mt_termsAndConditions';
export const MODAL_TYPE_ONBOARDING = 'mt_onboarding';
export const MODAL_TYPE_FAVORITES_ALERT = 'mt_favoritesAlert';
export const MODAL_TYPE_EDIT_EMAIL_PREFERENCES = 'mt_editEmailPreferences';
export const MODAL_TYPE_SEARCH = 'mt_search';
export const MODAL_TYPE_BUTTON_SCROLLING = 'mt_buttonOverlay';
export const MODAL_TYPE_PRODUCT_DETAILS = 'mt_productDetails';
export const MODAL_TYPE_HYBRID_STORES_OFFER = 'mt_hybridStoresOffer';
