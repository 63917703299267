import { merge } from 'lodash';
import parseClickUrl from './commonParsers/clickUrlParser';
import parseRebate from './commonParsers/rebateParser';

export const parseFlyout = (flyout, state) =>
  merge(flyout, {
    merchant: flyout.merchant
      ? {
        clickUrl: parseClickUrl(flyout.merchant.clickUrl, state),
        rebate: parseRebate(flyout.merchant.rebate, flyout.merchant.tiers, state),
      }
      : null,
  });

export default (response, state) =>
  response.map(category =>
    merge(category, {
      flyout: category.flyout ? parseFlyout(category.flyout, state) : null,
    }));
