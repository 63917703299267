import BaseApi, { filterInvalidParams } from './base';
import LocalStorageCache from '../cache/localStorage';

export class GspApi extends BaseApi {
  cache = new LocalStorageCache();

  /**
   *
   * @param params
   * @returns {Promise<void>}
   */
  getGSPMerchants(url, params = {}) {
    const validParams = [
      'column',
      'sid',
      'sortby',
      'query',
      'hits',
    ];
    const filteredValidParams = filterInvalidParams(params, validParams);

    return this.makeJsonpRequest({
      url: `${url}/search/v2/us_cartera_search/store.json?${this.serialize(filteredValidParams)}`,
    });
  }

  /**
   *
   * @param params
   * @returns {Promise<void>}
   */
    getGSPProducts(url, params = {}) {
      const validParams = [
        'sid',
        'h',
        'ci',
        'format',
        '_',
        'q',
        'cb',
      ];

      const defaultParams = {
        ci: '2',
        sid: 'us_ebates_ac_005',
        format: 'jsonp',
        _: Date.now(),
      };

      const combinedParams = { ...defaultParams, ...params };

      const filteredValidParams = filterInvalidParams(combinedParams, validParams);

      return this.makeJsonpRequest({
        url: `${url}/gsp-ac/autocomplete/v1/us_ebates_ac.list_ac?${this.serialize(filteredValidParams)}`,
        params: { // jsonp callback parameter is required (https://github.com/webmodules/jsonp)
          param: 'cb',
          name: 'callback',
        },
      });
    }
}

export default new GspApi();
