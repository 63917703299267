import { createSelector } from 'reselect';
import { merge } from 'lodash';
import { selectHasNoRewardsEnabled } from 'core/selectors/app';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';
import config from '../configs';

export const selectQuickSearch = state => state.quickSearch.merchants;
export const selectQuickSearchGspSuggestions = state => state.quickSearch.gspSuggestions;
export const selectQuickSearchCustomConfig = (state, customConfig) => customConfig || {};

/**
 * Finds matches within the merchant list based on inputed keywords
 */
const findQuickSearchMatches = (merchants, keywords, state) => {
  const { maxAnchorFront } = config;
  const query = keywords.replace(/[^a-zA-Z\u00C0-\u00FF0-9\s\-.'&"!$@+]/g, '').toLowerCase();
  const regEx = query.length < maxAnchorFront
    ? new RegExp(`^(${query})`, 'i') : new RegExp(`(${query})`, 'i');
  const hasNoRewardsEnabled = selectHasNoRewardsEnabled(state);

  return merchants.reduce((matches, merchant) => {
    if (merchant.type === 'Suspended Merchant' || (!hasNoRewardsEnabled && merchant.type === 'Deactivated Merchant')) {
      return matches;
    }

    if (query === (merchant.name || merchant.nameLatinized).toLowerCase()) {
      // eslint-disable-next-line no-param-reassign
      matches.exact = merchant;
    }

    const matchBySynonyms = merchant.synonyms && merchant.synonyms.split(',').indexOf(query) > -1;
    if (matchBySynonyms) {
      matches.synonyms.push({
        merchant,
      });
    } else {
      const nameMatch = regEx.exec(merchant.name) || (merchant.nameLatinized
        ? regEx.exec(merchant.nameLatinized) : false);
      // MOP-494: filter out instore merchants that don't have any active offers
      if (nameMatch && (!merchant.isInstore || (merchant.offers && merchant.offers.length > 0))) {
        const matchStartIndex = nameMatch && nameMatch.index;
        const matchText = merchant.name.substr(matchStartIndex, query.length);
        if (merchant.isInstore) {
          matches.instore.push({
            merchant,
            matchText,
          });
        } else {
          matches.online.push({
            merchant,
            matchText,
          });
        }
      }
    }

    return matches;
  }, {
    synonyms: [],
    instore: [],
    online: [],
    exact: {},
  });
};

const findQuickSearchGSPMatches = (merchants, keywords, state) => {
  const suggestionsGSP = selectQuickSearchGspSuggestions(state).gspSuggestions;
  const { maxAnchorFront } = config;
  const query = keywords.replace(/[^a-zA-Z\u00C0-\u00FF0-9\s\-.'&"!$@+]/g, '').toLowerCase();
  const regEx = query.length < maxAnchorFront
    ? new RegExp(`^(${query})`, 'i') : new RegExp(`(${query})`, 'i');
  const hasNoRewardsEnabled = selectHasNoRewardsEnabled(state);
  const stores = suggestionsGSP.reduce((storeIds, store) => {
      storeIds.set(parseInt(store.storeId, 10), null);

      return storeIds;
  }, new Map());

  if (!merchants.length || !stores) {
    return {
      synonyms: [],
      instore: [],
      online: [],
      exact: {},
    };
  }

  merchants.forEach((merchant) => {
    if (Array.from(stores.keys()).includes(merchant.id) || merchant.isInstore) {
      stores.set(merchant.id, merchant);
    }
  });

  const gspMerchants = Array.from(stores.values()).reduce((matches, merchant) => {
    if (!merchant) {
      return matches;
    }

    if (merchant.type === 'Suspended Merchant' || (!hasNoRewardsEnabled && merchant.type === 'Deactivated Merchant')) {
      return matches;
    }

    if (query === (merchant.name || merchant.nameLatinized).toLowerCase()) {
      // eslint-disable-next-line no-param-reassign
      matches.exact = merchant;
    }
    const nameMatch = regEx.exec(merchant.name) || (merchant.nameLatinized
      ? regEx.exec(merchant.nameLatinized) : false);
    const matchStartIndex = nameMatch && nameMatch.index;
    const matchText = nameMatch ? merchant.name.substr(matchStartIndex, query.length) : '';

    if (nameMatch && merchant.isInstore && merchant.offers && merchant.offers.length > 0) {
      matches.instore.push({
        merchant,
        matchText,
      });
    }

    if (merchant.type === 'online' || (hasNoRewardsEnabled && merchant.type === 'Deactivated Merchant')) {
      matches.online.push({
        merchant,
        matchText,
      });
    }

    return matches;
  }, {
    synonyms: [],
    instore: [],
    online: [],
    exact: {},
  });

  return gspMerchants;
};

export const selectQuickSearchIsLoading = createSelector(selectQuickSearch, quickSearch => quickSearch.isLoading);
export const selectQuickSearchIsLoaded = createSelector(selectQuickSearch, quickSearch => quickSearch.isLoaded);
export const selectQuickSearchMerchants = createSelector(selectQuickSearch, quickSearch => quickSearch.merchants);
export const selectQuickSearchConfig = createSelector(
  [selectQuickSearch, selectQuickSearchCustomConfig],
  (quickSearch, customConfig) => merge({}, quickSearch.config, customConfig),
);

export const selectQuickSearchMatchesAll = (state, keywords, shouldUseGsp) => {
  if (keywords.length >= config.minChars) {
    const merchants = selectQuickSearchMerchants(state);

    return shouldUseGsp
      ? findQuickSearchGSPMatches(merchants, keywords, state) : findQuickSearchMatches(merchants, keywords, state);
  }

  return {
    synonyms: [],
    instore: [],
    online: [],
    exact: {},
  };
};

// Get all matches and slice each type of match to the configured max size
export const selectQuickSearchMatches = (state, keywords, config = {}) => {
  const matches = selectQuickSearchMatchesAll(state, keywords, config.shouldUseGsp);
  const { maxMatchesOnTabletAndDesktop, maxMatchesOnMobile } = selectQuickSearchConfig(state, config);
  const isMobileMQ = selectIsMobileMQ(state);
  const maxMatches = isMobileMQ ? maxMatchesOnMobile : maxMatchesOnTabletAndDesktop;
  const synonyms = matches.synonyms.slice(0, maxMatches);
  const online = matches.online.slice(0, maxMatches);
  const instore = matches.instore.slice(0, maxMatches);
  const { exact } = matches;

  return {
    synonyms,
    instore,
    online,
    exact,
  };
};

export const selectMatchesLength = (matches, instoreIsEnabled) => (
  matches.synonyms.length + (instoreIsEnabled ? matches.instore.length : 0) + matches.online.length
);

export const selectQuickSearchMatchesAllLength = (state, keywords, instoreIsEnabled) => {
  const matches = selectQuickSearchMatchesAll(state, keywords);

  return selectMatchesLength(matches, instoreIsEnabled);
};

export const selectQuickSearchMatchesLength = (state, keywords, config, instoreIsEnabled) => {
  const matches = selectQuickSearchMatches(state, keywords, config);
  return selectMatchesLength(matches, instoreIsEnabled);
};

export const selectExactQuickSearchMatchName = (state, keywords, config) => {
  const { exact: { name } } = selectQuickSearchMatches(state, keywords, config);

  return name;
};

export const selectExactQuickSearchMatchSimilarStores = (state, keywords, config) => {
  const { exact: { relatedActiveMerchants } } = selectQuickSearchMatches(state, keywords, config);

  return (relatedActiveMerchants && relatedActiveMerchants.length) ? relatedActiveMerchants : null;
};

export const selectSynonymsQuickSearchMatches = (state, keywords, config) => (
  selectQuickSearchMatches(state, keywords, config).synonyms
);

export const selectOnlineQuickSearchMatches = (state, keywords, config) => (
  selectQuickSearchMatches(state, keywords, config).online
);

export const selectInstoreQuickSearchMatches = (state, keywords, config) => (
  selectQuickSearchMatches(state, keywords, config).instore
);

export const selectQuickSearchHasMatches = (state, keywords, config) => {
  const synonymsMatches = selectSynonymsQuickSearchMatches(state, keywords, config);
  const onlineMatches = selectOnlineQuickSearchMatches(state, keywords, config);
  const instoreMatches = selectInstoreQuickSearchMatches(state, keywords, config);
  return onlineMatches.length > 0 || instoreMatches.length > 0 || synonymsMatches.length > 0;
};
