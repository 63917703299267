import {
  PRODUCT_DETAILS_MODAL_TRIGGER,
  PRODUCT_DETAILS_GET_SUCCESS,
  PRODUCT_DETAILS_GET_FAILURE,
  PRODUCT_DETAILS_MODAL_CLEAR,
} from '../actions/types';

import { productDetailsModal as config } from '../configs';

const initialState = {
  config,
  clickData: null,
  product: null,
};

const productDetailsModal = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_MODAL_TRIGGER: {
      return { ...state, clickData: action.data.product };
    }
    case PRODUCT_DETAILS_GET_SUCCESS: {
      return { ...state, product: action.payload.response[0] };
    }
    case PRODUCT_DETAILS_GET_FAILURE: {
      return state;
    }
    case PRODUCT_DETAILS_MODAL_CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
};

export default productDetailsModal;
