import { compact } from 'lodash';

import { getPageName, getDisplayPageName } from 'core/utils/analytics/pageInfo';
import { BONUS_OFFERS_300X250_VIEW } from '../actions/types';

export const bonusOffers300x250GaActionEvents = {
  [BONUS_OFFERS_300X250_VIEW]: ({ offer }) => {
    const {
      merchant, bonusId, heading, description,
    } = offer;
    const { name: merchantName } = merchant || {};
    return {
      category: `${getDisplayPageName()} ${getPageName() === 'h' ? 'Page Trending Offers' : 'Right Rail'}`,
      action: 'Merchant Bonus Promotion View',
      label: compact([bonusId, merchantName, heading, description]).join('|'),
    };
  },
};

export const bonusOffers300x250ClickAndHoverEvents = [
  {
    selector: '.mn_bonusMerchantOffer300x250',
    click: {
      category: `${getDisplayPageName()} ${getPageName() === 'h' ? 'Page Trending Offers' : 'Right Rail'}`,
      action: 'Merchant Bonus Promotion Click',
    },
    label: (element) => {
      const {
        merchantName, bonusId, heading, description,
      } = element.dataset;
      // ignore 'description' if it has a 'false' value set as a string type,
      // (boolead <false> value was converded to the string type when attached to the DOM element as data attribute).
      // Lodash 'compact' method will remove falsy values from the resulting array in the next line
      const descriptionParsed = description === 'false' ? false : description;
      return compact([bonusId, merchantName, heading, descriptionParsed]).join('|');
    },
  },
];
