/* eslint-disable max-len */
import { SESSION_STORAGE } from 'core/utils/cache/base';
import { FAVORITES_CACHE_KEY, expirationTimestamp } from 'core/utils/favorites/keys';

import {
  requestActionGeneratorByType, SUCCESS_SUFFIX, FAILURE_SUFFIX,
} from './apis';

const rootActionPath = 'core/apis/member';

// action names
export const MEMBER_GET_FAVORITES = `${rootActionPath}/favorites/GET`;
export const MEMBER_GET_FAVORITES_SUCCESS = `${MEMBER_GET_FAVORITES}_${SUCCESS_SUFFIX}`;
export const MEMBER_GET_FAVORITES_FAILURE = `${MEMBER_GET_FAVORITES}_${FAILURE_SUFFIX}`;

export const MEMBER_POST_FAVORITES = `${rootActionPath}/favorites/POST`;
export const MEMBER_POST_FAVORITES_SUCCESS = `${MEMBER_POST_FAVORITES}_${SUCCESS_SUFFIX}`;
export const MEMBER_POST_FAVORITES_FAILURE = `${MEMBER_POST_FAVORITES}_${FAILURE_SUFFIX}`;

export const MEMBER_DELETE_FAVORITES = `${rootActionPath}/favorites/DELETE`;
export const MEMBER_DELETE_FAVORITES_SUCCESS = `${MEMBER_DELETE_FAVORITES}_${SUCCESS_SUFFIX}`;
export const MEMBER_DELETE_FAVORITES_FAILURE = `${MEMBER_DELETE_FAVORITES}_${FAILURE_SUFFIX}`;

export const MEMBER_POST_UI_COMPONENT_METADATA = `${rootActionPath}/uiComponent/:component/metadata/POST`;
export const MEMBER_POST_UI_COMPONENT_METADATA_SUCCESS = `${MEMBER_POST_UI_COMPONENT_METADATA}_${SUCCESS_SUFFIX}`;
export const MEMBER_POST_UI_COMPONENT_METADATA_FAILURE = `${MEMBER_POST_UI_COMPONENT_METADATA}_${FAILURE_SUFFIX}`;

export const MEMBER_AUTHENTICATION_CHECK = `${rootActionPath}/authentication/CHECK`;
export const MEMBER_AUTHENTICATION_COMPLETE = `${rootActionPath}/authentication/COMPLETE`;

export const MEMBER_POST_INSTORE_CARD_LINK = `${rootActionPath}/linked-offers`;
export const MEMBER_POST_INSTORE_CARD_LINK_SUCCESS = `${MEMBER_POST_INSTORE_CARD_LINK}_${SUCCESS_SUFFIX}`;
export const MEMBER_POST_INSTORE_CARD_LINK_FAILURE = `${MEMBER_POST_INSTORE_CARD_LINK}_${FAILURE_SUFFIX}`;

export const MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK = `${rootActionPath}/multi-linked-offers`;
export const MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK_SUCCESS = `${MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK}_${SUCCESS_SUFFIX}`;
export const MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK_FAILURE = `${MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK}_${FAILURE_SUFFIX}`;

export const MEMBER_GET_INSTORE_PAYMENT_CARDS = `${rootActionPath}/payment-cards/GET`;
export const MEMBER_GET_INSTORE_PAYMENT_CARDS_SUCCESS = `${MEMBER_GET_INSTORE_PAYMENT_CARDS}_${SUCCESS_SUFFIX}`;
export const MEMBER_GET_INSTORE_PAYMENT_CARDS_FAILURE = `${MEMBER_GET_INSTORE_PAYMENT_CARDS}_${FAILURE_SUFFIX}`;

export const MEMBER_DELETE_INSTORE_PAYMENT_CARD = `${rootActionPath}/payment-cards/DELETE`;
export const MEMBER_DELETE_INSTORE_PAYMENT_CARD_SUCCESS = `${MEMBER_DELETE_INSTORE_PAYMENT_CARD}_${SUCCESS_SUFFIX}`;
export const MEMBER_DELETE_INSTORE_PAYMENT_CARD_FAILURE = `${MEMBER_DELETE_INSTORE_PAYMENT_CARD}_${FAILURE_SUFFIX}`;

export const MEMBER_REGISTER_TOKEN = `${rootActionPath}/register-token/POST`;
export const MEMBER_REGISTER_TOKEN_FAILURE = `${MEMBER_REGISTER_TOKEN}_${FAILURE_SUFFIX}`;
export const MEMBER_REGISTER_TOKEN_SUCCESS = `${MEMBER_REGISTER_TOKEN}_${SUCCESS_SUFFIX}`;

export const MEMBER_POST_PHONE_NUMBER = `${rootActionPath}/in-store-sms-registration`;
export const MEMBER_POST_PHONE_NUMBER_SUCCESS = `${MEMBER_POST_PHONE_NUMBER}_${SUCCESS_SUFFIX}`;
export const MEMBER_POST_PHONE_NUMBER_FAILURE = `${MEMBER_POST_PHONE_NUMBER}_${FAILURE_SUFFIX}`;

export const MEMBER_SET_ONBOARDING_PROPERTY = `${rootActionPath}/in-store-payment-onboarding`;
export const MEMBER_SET_ONBOARDING_PROPERTY_SUCCESS = `${MEMBER_SET_ONBOARDING_PROPERTY}_${SUCCESS_SUFFIX}`;
export const MEMBER_SET_ONBOARDING_PROPERTY_FAILURE = `${MEMBER_SET_ONBOARDING_PROPERTY}_${FAILURE_SUFFIX}`;

// action generator methods
const generateFetchFavoriteAction = requestActionGeneratorByType(MEMBER_GET_FAVORITES);
const generateAddFavoriteAction = requestActionGeneratorByType(MEMBER_POST_FAVORITES);
const generateDeleteFavoriteAction = requestActionGeneratorByType(MEMBER_DELETE_FAVORITES);
const generatePostUIComponentMetadataAction = requestActionGeneratorByType(MEMBER_POST_UI_COMPONENT_METADATA);
const generateInstoreCardLinkAction = requestActionGeneratorByType(MEMBER_POST_INSTORE_CARD_LINK);
const generateInstoreMultiOfferCardLinkAction = requestActionGeneratorByType(MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK);
const generateInstorePaymentCardsAction = requestActionGeneratorByType(MEMBER_GET_INSTORE_PAYMENT_CARDS);
const generateDeletePaymentCardAction = requestActionGeneratorByType(MEMBER_DELETE_INSTORE_PAYMENT_CARD);
const generateRegisterTokenAction = requestActionGeneratorByType(MEMBER_REGISTER_TOKEN);
const generateRegisterMobileNumberAction = requestActionGeneratorByType(MEMBER_POST_PHONE_NUMBER);
const generateSetInstoreAddCardOnboardingProperty = requestActionGeneratorByType(MEMBER_SET_ONBOARDING_PROPERTY);

// exported methods
export const checkIfUserFullyAuthenticated = requestActionGeneratorByType(MEMBER_AUTHENTICATION_CHECK);

export const fetchFavorites = (params = {}, options = {}) => {
  const updatedOptions = {
    ...options,
    cache: {
      recordKey: FAVORITES_CACHE_KEY,
      isEnabled: true,
      type: SESSION_STORAGE,
      expirationTimestamp,
      ...options.cache,
    },
  };

  return generateFetchFavoriteAction(params, updatedOptions);
};

/**
 * Generate an action to add a favorite merchant by id
 * @param {number} merchantId
 * @param {object} params
 * @param {object} options
 * @returns {function}
 */
export const addFavorite = (merchantId, params = {}, options = {}, config) => (
  generateAddFavoriteAction(params, options, { merchantId, ...config })
);

/**
 * Generate an action to delete a favorite merchant by id
 * @param {number} merchantId
 * @param {object} params
 * @param {object} options
 * @returns {function}
 */
export const deleteFavorite = (merchantId, params = {}, options = {}) => (
  generateDeleteFavoriteAction(params, options, { merchantId })
);

/**
 * Generate an action to post an update to ui-component metadata endpoint
 * @param {string} component
 * @param {array.<object>} metadata
 * @param {object} params
 * @param {object} options
 * @returns {function}
 */
export const postUIComponentMetadata = (component, metadata = [], params = {},
                                        options = {}) => (
  generatePostUIComponentMetadataAction(params, options, {
    component,
    metadata,
  })
);

/**
 * Generate an action to post an instore card link endpoint
 * @param offerId
 * @param paymentCard
 * @param params
 * @param options
 * @returns {{data: {}, options: {}, type: string, params: {}}}
 */
export const postInstoreCardLink = (offerId, paymentCard = {}, params = {}, options = {}) => (
  generateInstoreCardLinkAction(params, options, {
    offerId,
    paymentCard,
  })
);

export const postInstoreMultiOfferCardLink = (paymentCard = {}, params = {}, options = {}) => (
  generateInstoreMultiOfferCardLinkAction(params, options, {
    paymentCard,
  })
);

export const getInstorePaymentCards = (params = {}, options = {}) => (
  generateInstorePaymentCardsAction(params, options)
);

export const deleteInstorePaymentCard = (cardId, params = {}, options = {}) => (
  generateDeletePaymentCardAction(params, options, {
    cardId,
  })
);

export const postInstoreRegisterToken = (params = {}, options = {}) => (
  generateRegisterTokenAction(params, options)
);

export const postInstoreMobileNumber = (mobileNumber, params = {}, options = {}) => (
  generateRegisterMobileNumberAction(params, options, {
    mobileNumber,
  })
);

export const setInstoreAddCardOnbordingProperty = (params = {}, options = {}) => (
  generateSetInstoreAddCardOnboardingProperty(params, options)
);
