import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { selectSiteUrl } from 'core/selectors/app';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { highlightMatch } from '../../utils/highlightMatch';

export function ProductGspMatches({ productGspMatches, keywords, isPopUpView }) {
  const siteUrl = useSelector(selectSiteUrl);
  const productGspMatchesToShow = productGspMatches.slice(0, isPopUpView ? 4 : 5);

  if (isEmpty(productGspMatchesToShow)) {
    return null;
  }

  return (
    <div className="mn_quickSearchWrap mn_quickSearchGspProductsWrap">
      <div className="mn_quickSearchItems">
        { productGspMatchesToShow.map(({ term, categoryId, categoryName }) => (
          <div
            key={`${term}-${categoryId}`}
            data-category={categoryName}
            data-search={keywords}
            className="mn_quickSearchItem"
          >
            <a
              className="mn_quickSearchMatch mn_quickSearchProductMatch"
              href={`${siteUrl}/sr____.htm?keywords=${encodeURIComponent(term)}&category_id=${categoryId}`}
            >
              <div className="mn_quickSearchMatchHighlight">
                {highlightMatch(term, keywords)} in {categoryName}
              </div>
            </a>
          </div>
        ))}
      </div>
      <a
        className="mn_quickSearchLink"
        href={`${siteUrl}/sr____.htm?keywords=${encodeURIComponent(keywords)}`}
      >
        View all product results
      </a>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><ProductGspMatches {...props} /></ComponentErrorBoundary>;
