import 'core/utils/publicPath';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { isClickLink } from 'core/utils/helpers/url';
import { addIsTouchDeviceClassName } from 'core/utils/helpers/isTouchDevice';
import ccpaDeletedMemberRedirect from 'core/utils/ccpaDeletedMemberRedirect';
import init from 'core/utils/interstitial';

import store from './store';
import './styles/style.scss';

import Routes from './routes';
import Wrapper from './modules/Wrapper/Wrapper';

addIsTouchDeviceClassName();
ccpaDeletedMemberRedirect();
const isClick = isClickLink(window.location.href);

if (isClick) {
  init();
} else {
  render(
    <Provider store={store}>
      <Wrapper>
        <Router>
          <Routes />
        </Router>
      </Wrapper>
    </Provider>,
    document.getElementById('app'),
  );
}
