/*
Use this module to check for existence of our browser extension.
Extension injects custom tag into page.
Wait 1200ms after document.ready for it.
If promise resolves, button is installed on current browser. If fail, button is not installed on current browser.
 */

 const detectButton = new Promise((resolve) => {
   setTimeout(() => {
     resolve(!!document.querySelector('mntoolbar'));
   }, 1200);
 });

 export default detectButton;
