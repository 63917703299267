import { CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS, CREDIT_CARD_MARKETING_BANNER_GET_FAILURE } from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  placement: {},
};

const creditCardMarketingBannerParser = (response = []) => {
  const CONTENT_ITEM_TYPE_WITH_TEXT = 59;
  const CONTENT_ITEM_TYPE_WITH_IMAGE = 40;

  const textContent = response.filter(elem => (
    elem.contentItemTypeId === CONTENT_ITEM_TYPE_WITH_TEXT
  ))[0];

  const imageContent = response.filter(elem => (
    elem.contentItemTypeId === CONTENT_ITEM_TYPE_WITH_IMAGE
  ))[0];

  if (!textContent || !imageContent) {
    return {};
  }

  return {
    ...textContent,
    assets: {
      ...textContent.assets,
      image: imageContent.assets.image,
    },
  };
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const creditCardMarketingBanner = (state = initialState, action) => {
  switch (action.type) {
    case CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placement: creditCardMarketingBannerParser(action.payload.response),
      };
    }
    case CREDIT_CARD_MARKETING_BANNER_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placement: {},
      }; // we'd have to decide what to do with error state
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }

      return state;
    }
  }
};

export default creditCardMarketingBanner;
