import ReactGA from 'react-ga4';
import UrlParse from 'url-parse';
import { compact } from 'lodash';

import { findAncestorByClassName, findDataAttrInAncestorTree } from 'core/utils/helpers/dom';
import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';

import {
  getMerchantExperiencePageViewType,
} from 'core/modules/MerchantExperience/utils/getMerchantExperiencePageViewType';

import {
  MERCHANT_EXPERIENCE_PAGE_VIEW, MERCHANT_EXPERIENCE_BONUS_VIEW,
  MERCHANT_EXPERIENCE_SUSPENDED_MERCHANT_VIEW, MERCHANT_EXPERIENCE_TYPE_DIRECT_LINK,
  MERCHANT_EXPERIENCE_TYPE_MULTIPLE, MERCHANT_EXPERIENCE_TYPE_SINGLE, MERCHANT_EXPERIENCE_TYPE_SUSPENDED,
} from '../actions/types';

// This function is called asynchronously as an Merchant Experience API response handler.
// see 'modules/MerchantExperience/analytics/index.js' for details
const getCustomMerchantPageName = (initialState, merchants) => {
  const { keywords } = getParamsFromSearch();

  const merchantType = getMerchantExperiencePageViewType(merchants, keywords);

  switch (merchantType) {
    case MERCHANT_EXPERIENCE_TYPE_MULTIPLE: {
      return 'me_multiple';
    }
    case MERCHANT_EXPERIENCE_TYPE_SINGLE: {
      return 'me_keyword';
    }
    case MERCHANT_EXPERIENCE_TYPE_DIRECT_LINK: {
      return 'me_direct';
    }
    case MERCHANT_EXPERIENCE_TYPE_SUSPENDED: {
      return 'me_tombstone';
    }
    default: {
      return 'me';
    }
  }
};

export const merchantExperienceGaActionEvents = {
  [MERCHANT_EXPERIENCE_PAGE_VIEW]: (action) => {
    const { merchants } = action;
    const pageName = getCustomMerchantPageName(null, merchants);
    const currentUrl = new UrlParse(window.location.href, true);
    const { query } = currentUrl;
    const queryParams = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');

    const customPageName = `/${pageName}?${queryParams}`;
    let merchantName;
    if (merchants.length === 0) {
      merchantName = null;
    } else if (merchants.length > 1) {
      merchantName = 'multiple merchants';
    } else {
      merchantName = merchants[0].name;
    }

    return ReactGA.gtag('event', 'page_view', {
      page: customPageName,
      page_name: pageName, // eslint-disable-line camelcase
      merchant: merchantName,
    });
  },
  [MERCHANT_EXPERIENCE_BONUS_VIEW]: ({ bonusInfo }) => {
    const {
      merchantName, id, heading, description,
    } = bonusInfo;
    return {
      category: 'ME Page Merchant Bonus Promotion',
      action: 'Merchant Bonus View',
      label: compact([id, merchantName, heading, description]).join('|'),
    };
  },
  [MERCHANT_EXPERIENCE_SUSPENDED_MERCHANT_VIEW]: ({ merchant }) => {
    const { id, name } = merchant;

    return {
      category: 'Tombstone page',
      action: 'Page view',
      label: `${id}|${name}`,
      value: 1,
    };
  },
};

const getMerchantBonusLabel = (merchantBonus = {}) => {
  const {
    merchantName, bonusId, heading, description,
  } = merchantBonus.dataset;
  // ignore 'description' if it has a 'false' value set as a string type,
  // (boolead <false> value was converded to the string type when attached to the DOM element as data attribute).
  // Lodash 'compact' method will remove falsy values from the resulting array in the next line
  const descriptionParsed = description === 'false' ? false : description;
  return compact([bonusId, merchantName, heading, descriptionParsed]).join('|');
};

export const merchantExperienceClickAndHoverEvents = [
  {
    selector: '.mn_merchantInfoTile .mn_merchExpInstoreOfferLink',
    click: {
      category: 'In-store',
      action: 'Merchant Detail Page In-store Offer click',
    },
    label: ({ dataset }) => `${dataset.merchantId}:${dataset.merchantName}`,
  },

  {
    selector: '.mn_merchantExperience .mn_shopNowLink',
    click: {
      category: element => findDataAttrInAncestorTree(element, 'pageType'),
      action: 'Shop now store',
    },
    label: ({ dataset }) => dataset.merchantName,
  },

  {
    selector: '.mn_merchantExperience .mn_shopOfferButton',
    click: {
      category: (element) => {
        const result = findDataAttrInAncestorTree(element, 'pageType');
        return result;
      },
      action: 'Shop now offer',
    },
    label: (element) => {
      const { merchantName } = element.dataset;
      const offerElement = findAncestorByClassName(element, 'mn_merchantExperienceOffer') || {};
      const descriptionText = offerElement.getElementsByClassName('mn_descriptionText')[0].innerText || '';
      return `${merchantName} | ${descriptionText}`;
    },
  },

  {
    selector: '.mn_merchantExperience .mn_favoriteIcon',
    click: {
      category: element => findDataAttrInAncestorTree(element, 'pageType'),
      action: (element) => {
        // Note: if in a moment of click the favorite icon had a 'mn_favorited' className
        // then the 'Add to favorite' API call will be made.
        // So the 'isAddToFavoriteClick' flag should be 'false' when user clicks on icon with className 'mn_favorited'
        const isAddToFavoriteClick = !element.classList.contains('mn_favorited');
        return isAddToFavoriteClick ? 'Add favorite' : 'Remove favorite';
      },
    },
    label: ({ dataset }) => dataset.merchantName,
  },

  {
    selector: '.mn_merchantExperience .mn_relatedStores .mn_merchantExperienceLink',
    click: {
      category: element => findDataAttrInAncestorTree(element, 'pageType'),
      action: 'Related stores click',
    },
    label: (element) => {
      const merchantTile = element.getElementsByClassName('mn_merchantTile')[0];
      const { merchantName } = merchantTile.dataset;
      return merchantName;
    },
  },
  {
    selector: '.mn_merchantExperience .mn_merchantBonusDetails .mn_termAndConditionsButton',
    click: {
      category: 'ME Page Merchant Bonus Promotion',
      action: 'Merchant Bonus Terms Click',
    },
    label: element => getMerchantBonusLabel(findAncestorByClassName(element, 'mn_merchantBonusDetails')),
  },
  {
    selector: '.mn_merchantExperience .mn_merchantBonusDetails .mn_shopMoreButton',
    click: {
      category: 'ME Page Merchant Bonus Promotion',
      action: 'Merchant Bonus Click',
    },
    label: element => getMerchantBonusLabel(findAncestorByClassName(element, 'mn_merchantBonusDetails')),
  },
  {
    selector: '.mn_merchantExperience .mn_shopFeaturedOfferButton',
    click: {
      category: 'ME Page Merchant Bonus Promotion',
      action: 'Merchant Bonus Featured Offer Click',
    },
    label: (element) => {
      const merchantExperience = findAncestorByClassName(element, 'mn_merchantExperience') || {};
      const bonus = merchantExperience.getElementsByClassName('mn_merchantBonusDetails')[0];
      return getMerchantBonusLabel(bonus);
    },
  },
  {
    selector: '.mn_merchantExperienceSuspendedMerchant .mn_merchantExperienceLink',
    click: {
      category: 'Tombstone page',
      action: 'Store click',
    },
    label: (element) => {
      const suspendedMerchantName = findDataAttrInAncestorTree(element, 'suspendedMerchantName');
      const merchantName = element.getAttribute('title');
      const sectionTitle = findDataAttrInAncestorTree(element, 'sectionTitle') || '';

      return `${suspendedMerchantName}|${sectionTitle}|${merchantName}`;
    },
  },
];
