import {
  HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_GET_SUCCESS, HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_GET_FAILURE,
  HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_CLEAR,
  } from '../actions/types';

  const initialState = {
    placements: [],
    isLoading: false,
    isLoaded: false,
  };

const parseResponse = (payload) => payload?.response?.docs || [];

const gspMerchants = (state = initialState, action) => {
    switch (action.type) {
      case HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_GET_SUCCESS: {
        return {
          ...state,
          placements: parseResponse(action.payload),
          isLoading: false,
          isLoaded: true,
        };
      }
      case HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_GET_FAILURE: {
        return {
          ...state,
          placements: [],
          isLoading: false,
          isLoaded: true,
        };
      }
      case HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_CLEAR: {
        return {
          ...initialState,
        };
      }
      default: {
        const { options = {} } = action;
        if (options.success === HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_GET_SUCCESS) {
          return {
            ...state,
            isLoading: true,
            isLoaded: false,
          };
        }
      }
    }

    return state;
  };

  export default gspMerchants;
