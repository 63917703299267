import React from 'react';
import { isNil } from 'lodash';
import replaceTemplateValueInString from 'core/utils/helpers/replaceTemplateValueInString';
import { useSelector } from 'react-redux';

import { selectInstoreIsEnabled } from 'core/selectors/app';

import OnlineMatches from './partials/OnlineMatches';
import SynonymsMatches from './partials/SynonymsMatches';
import InstoreMatches from './partials/InstoreMatches';
import NoMatches from './partials/NoMatches';

import {
  selectQuickSearchConfig,
  selectSynonymsQuickSearchMatches,
  selectOnlineQuickSearchMatches,
  selectInstoreQuickSearchMatches,
  selectQuickSearchMatchesAllLength,
  selectQuickSearchMatchesLength,
} from '../selectors';

export function QuickSearchMatches({
  inquiryOrderStoreInputClickHandler, keywords, footerText, getTitle,
  quickSearchResultsRef, config, localMerchantClickHandler, isPopUpView,
}) {
  config.shouldUseGsp = false;
  const instoreIsEnabled = useSelector(state => (!isNil(config && config.instoreIsEnabled)
    ? config.instoreIsEnabled : selectInstoreIsEnabled(state)));
  const localConfig = useSelector(state => selectQuickSearchConfig(state, config));
  const onlineMatches = useSelector(state => selectOnlineQuickSearchMatches(state, keywords, config));
  const synonymsMatches = useSelector(state => selectSynonymsQuickSearchMatches(state, keywords, config));
  const instoreMatches =
    useSelector(state => (instoreIsEnabled ? selectInstoreQuickSearchMatches(state, keywords, config) : []));
  const quickSearchMatchesAllLength =
    useSelector(state => selectQuickSearchMatchesAllLength(state, keywords, config, instoreIsEnabled));
  const quickSearchMatchesLength =
    useSelector(state => selectQuickSearchMatchesLength(state, keywords, config, instoreIsEnabled));

  if (synonymsMatches.length === 0 && onlineMatches.length === 0 && instoreMatches.length === 0) {
    return (
      <NoMatches inquiryOrderStoreInputClickHandler={inquiryOrderStoreInputClickHandler} />
    );
  }

  return (
    <div className="mn_quickSearchResults mn_quickSearchResultsNonGsp" ref={quickSearchResultsRef}>
      {isPopUpView && <h3 className="mn_quickSearchMatchingTitle">Matching stores</h3>}
      <SynonymsMatches
        synonymsMatches={synonymsMatches}
        localConfig={localConfig}
        keywords={keywords}
        localMerchantClickHandler={localMerchantClickHandler}
        getTitle={getTitle}
      />
      <OnlineMatches
        onlineMatches={onlineMatches}
        localConfig={localConfig}
        keywords={keywords}
        localMerchantClickHandler={localMerchantClickHandler}
        isPopUpView={isPopUpView}
        instoreMatches={instoreMatches}
        getTitle={getTitle}
      />
      <InstoreMatches
        instoreMatches={instoreMatches}
        isPopUpView={isPopUpView}
        getTitle={getTitle}
      />
      { footerText &&
        <div
          className="mn_quickSearchFooter"
          dangerouslySetInnerHTML={{
            __html: replaceTemplateValueInString(footerText, {
              keywords,
              curr: quickSearchMatchesLength,
              total: quickSearchMatchesAllLength,
            }),
          }}
        />
      }
    </div>
  );
}

export default QuickSearchMatches;
