import {
    HEADER_HOLIDAY_IMAGE_GET_SUCCESS, HEADER_HOLIDAY_IMAGE_GET_FAILURE,
  } from '../actions/types';

  import config from '../configs';

  const initialState = {
    config,
    isLoading: false,
    isLoaded: false,
    data: {},
  };

  const headerHolidayImage = (state = initialState, action) => {
    switch (action.type) {
      case HEADER_HOLIDAY_IMAGE_GET_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          isLoaded: true,
          data: action.payload.response[0],
        };
      }
      case HEADER_HOLIDAY_IMAGE_GET_FAILURE: {
        return {
          ...state,
          isLoading: false,
          isLoaded: true,
          data: {},
        };
      }
      default: {
        const { options = {} } = action;
        if (options.success === HEADER_HOLIDAY_IMAGE_GET_SUCCESS) {
          return {
            ...state,
            isLoading: true,
            isLoaded: false,
          };
        }
      }
    }

    return state;
  };

  export default headerHolidayImage;
