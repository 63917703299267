import { merge, shuffle } from 'lodash';
import {
  INFOBAR_DOTW_GET_SUCCESS,
  INFOBAR_DOTW_GET_FAILURE,
  INFOBAR_SALES_GET_SUCCESS,
  INFOBAR_SALES_GET_FAILURE,
} from '../actions/types';

import infoBarConfig from '../configs';

const { dealOfTheWeek, salesInfoBar } = infoBarConfig;

const initialState = {
  config: infoBarConfig,
  dealOfTheWeek: {
    config: dealOfTheWeek, offer: {}, isLoading: false, isLoaded: false,
  },
  salesInfoBar: {
    config: salesInfoBar, offer: {}, isLoading: false, isLoaded: false,
  },
};

const dealOfTheWeekParser = (response = []) => {
  const [offer = {}] = response;
  const { assets } = offer;

  if (!assets) { return offer; }

  return {
    ...offer,
    isDealOfTheWeek: true,
  };
};

const salesInfoBarParser = (response = []) => {
  const merchantDependentOffers = response.filter(offer => !!offer.merchant);

  return merchantDependentOffers.length > 0 ? shuffle(merchantDependentOffers)[0] : {};
};

const infoBar = (state = initialState, action) => {
  switch (action.type) {
    case INFOBAR_DOTW_GET_SUCCESS: {
      return merge({}, state, {
        dealOfTheWeek: {
          isLoading: false,
          isLoaded: true,
          offer: dealOfTheWeekParser(action.payload.response),
        },
      });
    }
    case INFOBAR_DOTW_GET_FAILURE: {
      return merge({}, state, {
        dealOfTheWeek: {
          isLoading: false,
          isLoaded: true,
          offer: {},
        },
      });
    }
    case INFOBAR_SALES_GET_SUCCESS: {
      return merge({}, state, {
        salesInfoBar: {
          isLoading: false,
          isLoaded: true,
          offer: salesInfoBarParser(action.payload.response),
        },
      });
    }
    case INFOBAR_SALES_GET_FAILURE: {
      return merge({}, state, {
        salesInfoBar: {
          isLoading: false,
          isLoaded: true,
          offer: {},
        },
      });
    }
    default: {
      // Handle setting isLoading to true for raw OCAPI action
      const { options = {} } = action;
      if (options.success === INFOBAR_DOTW_GET_SUCCESS) {
        return merge({}, state, {
          dealOfTheWeek: {
            isLoading: true,
          },
        });
      }
      if (options.success === INFOBAR_SALES_GET_SUCCESS) {
        return merge({}, state, {
          salesInfoBar: {
            isLoading: true,
          },
        });
      }
    }
  }

  return state;
};

export default infoBar;
