import { isEmpty } from 'lodash';
import { selectProductImageUrlPrefix } from 'core/selectors/app';

const isCurrentProtocolSecure = () => window.location.protocol === 'https:';
const isImageUrlsProtocolSecure = imageUrl => /^https:\/\/.*$/.test(imageUrl);
const generateSecureImageUrl = (imageUrl, state) => {
  const imgPhpScriptPath = 'partner/img.php?r=';
  const productImageUrlPrefix = selectProductImageUrlPrefix(state);
  const prefix = !isEmpty(productImageUrlPrefix) ? productImageUrlPrefix : '';

  return `${prefix}${imgPhpScriptPath}${encodeURIComponent(imageUrl)}`;
};

export default (imageUrl, state) => {
  // if we not use https rigth now or imageUrl has 'https' - just return value
  if (!isCurrentProtocolSecure() || isImageUrlsProtocolSecure(imageUrl)) {
    return imageUrl;
  }
  // if imageUrl hasn't https - add prefix
  return generateSecureImageUrl(imageUrl, state);
};
