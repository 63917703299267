import { DYNAMIC_MERCHANTS_GET_SUCCESS, DYNAMIC_MERCHANTS_GET_FAILURE } from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  defaults: {
    merchantsNumberOnInitialLoad: 0,
    isLoaded: false,
    isLoading: false,
    merchants: [],
  },
};

const dynamicMerchants = (state = initialState, action) => {
  const namespaceId = action?.options?.namespace || action?.context?.options?.namespace || 'default';
  const namespace = `namespace-${namespaceId}`;
  const { defaults } = state;
  const { [namespace]: namespaceState = defaults } = state;
  switch (action.type) {
    case DYNAMIC_MERCHANTS_GET_SUCCESS: {
      const { response } = action.payload;
      const { isInitialLoad } = action?.context?.options;
      return {
        ...state,
        [namespace]: {
          ...namespaceState,
          merchants: response,
          isLoading: false,
          isLoaded: true,
          merchantsNumberOnInitialLoad: isInitialLoad ? response.length : namespaceState.merchantsNumberOnInitialLoad,
        },
      };
    }
    case DYNAMIC_MERCHANTS_GET_FAILURE: {
      return {
        ...state,
        [namespace]: {
          ...namespaceState,
          merchants: [],
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === DYNAMIC_MERCHANTS_GET_SUCCESS) {
        return {
          ...state,
          [namespace]: {
            ...namespaceState,
            isLoading: true,
          },
        };
      }

      return state;
    }
  }
};

export default dynamicMerchants;
