import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { creditCardMarketingBanner as orgConfig } from 'org/configs/modules.config';
import { CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS, CREDIT_CARD_MARKETING_BANNER_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_group_id: 11430,
        fields: [
          'assets',
          'clickUrl',
          'contentItemTypeId',
        ],
        section_id: 10457,
      },
      options: {
        success: CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS,
        failure: CREDIT_CARD_MARKETING_BANNER_GET_FAILURE,
        cache: {
          isEnabled: true,
          isPublishedData: true,
        },
      },
    },
    useButtonView: true,
    showCardTerms: false,
    getShowCardTip: () => false,
    showCardHeading: false,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
