import { useState, useEffect, useRef } from 'react';
import keyCodes from 'core/utils/helpers/keyCodes';

export const useDropdown = (dropdownRef, defaultFocusedState = false) => {
  const [isFocused, setIsFocused] = useState(defaultFocusedState);
  const clickOutsideTimerId = useRef();

  const toggleSubnav = () => {
    setIsFocused(!isFocused);
  };

  const showSubnav = () => {
    setIsFocused(true);
  };

  const hideSubnav = (event) => {
    if (event && (event.type === 'blur')) {
      // Hide dropdown if nothing is focused inside it
      !event.currentTarget.contains(event.relatedTarget) && setIsFocused(false);
      return;
    }

    setIsFocused(false);
  };

  const handleClickOutside = (event) => {
    if (!dropdownRef.current.contains(event.target)) {
      clickOutsideTimerId.current = setTimeout(() => setIsFocused(false), 100);
    }
  };

  const handleKeyDown = (event) => {
    const pressedKey = event.keyCode;
    if (pressedKey === keyCodes.ESCAPE) {
      hideSubnav(event);
    }
  };

  useEffect(() => {
    // Track mousedown event only if dropdown ref is passed
    if (dropdownRef?.current) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  // Clear timeout on component unmount
  useEffect(() => () => clearTimeout(clickOutsideTimerId.current), []);

  useEffect(() => {
      document.addEventListener('keydown', handleKeyDown);

      return () => document.removeEventListener('keydown ', handleKeyDown);
  }, []);

  return {
    toggleSubnav,
    hideSubnav,
    showSubnav,
    isFocused,
    dropdownClassName: isFocused ? 'mn_focused' : '',
  };
};
