import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import detectButton from 'core/utils/helpers/detectButton';
import { selectSiteUrl, selectToolbarConfig } from 'core/selectors/app';
import { triggerNavBannerView } from './actions';
import navigationBannerConfig from './configs';

import './NavBanner.scss';

function NavigationBanner({ isNavigationOpen }) {
  const dispatch = useDispatch();
  const siteUrl = useSelector(selectSiteUrl);
  const toolbarConfig = useSelector(selectToolbarConfig);
  const { enableButtonMarketing } = toolbarConfig || {};
  const { bannerText: { button, favorites }, showFavoritesBanner } = navigationBannerConfig;

  const [isButtonInstalled, setIsButtonInstalled] = useState(false);
  const shouldShowButtonBanner = enableButtonMarketing && !isButtonInstalled;

  detectButton.then((hasButton) => setIsButtonInstalled(hasButton));

  useEffect(() => {
    if (isNavigationOpen) {
      const title = shouldShowButtonBanner ? button.title : favorites.title;
      dispatch(triggerNavBannerView(title));
    }
  }, [isNavigationOpen]);

  const renderButtonBanner = () => (
    <div className="mn_navBanner mn_navButtonBanner">
      <div className="mn_navBannerContent">
        <h3>{button.title}</h3>
        <p>
          {button.text()}{' '}
          <span className="mn_navBannerLinkWrap">
            <a href={`${siteUrl}/button`}>{button.ctaTitle}</a>
          </span>
        </p>
      </div>
    </div>
  );

  const renderFavoritesBanner = () => {
    if (!showFavoritesBanner) {
      return null;
    }

    return (
      <div className="mn_navBanner mn_navFavoritesBanner">
        <div className="mn_navBannerContent">
          <h3>{favorites.title}</h3>
          <p>
            {favorites.text()}
            <span className="mn_navBannerLinkWrap">
              <a href={`${siteUrl}/favorites____.htm`}>{favorites.ctaTitle}</a>
            </span>
          </p>
        </div>
      </div>
    );
  };

  return shouldShowButtonBanner ? renderButtonBanner() : renderFavoritesBanner();
}

export default NavigationBanner;
