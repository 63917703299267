// All core level shared reducers should be imported here

import app from 'core/modules/App/reducers';
import accountSummary from 'core/modules/AccountSummary/reducers';
import buyOnlinePickupInstore from 'core/modules/BuyOnlinePickupInstore/reducers';
import favorites from 'core/reducers/favorites';
import authentication from 'core/reducers/authentication';
import quickSearch from 'core/modules/QuickSearch/reducers';
import hybridQuickSearch from 'core/modules/HybridQuickSearch/reducers';
import merchantLogos from 'core/modules/MerchantLogos/reducers';
import merchantOfferModal from 'core/modules/Modal/components/MerchantOfferModal/reducers';
import hybridStoresOfferModal from 'core/modules/Modal/components/HybridStoresOfferModal/reducers';
import instoreOfferModal from 'core/modules/Modal/components/InstoreOfferModal/reducers';
import instoreManageCardsModal from 'core/modules/Modal/components/InstoreModals/ManageCardsModal/reducers';
import instoreAddNewCardModal from 'core/modules/Modal/components/InstoreModals/AddNewCardModal/reducers';
import instoreCardsListModal from 'core/modules/Modal/components/InstoreModals/ManageCardsModal/components/reducers';
import instoreSMSNumberModal from 'core/modules/Modal/components/InstoreModals/SMSNumberModal/reducers';
import instoreMultiLinkModal from 'core/modules/Modal/components/InstoreModals/MultiLinkModal/reducers';
import cardLinkOffer from 'core/modules/Instore/CardLinkOffer/reducers';
import modal from 'core/modules/Modal/reducers';
import nav from 'core/modules/Navigation/Nav/reducers';
import navCategories from 'core/modules/Navigation/NavCategories/reducers';
import navPopularStores from 'core/modules/Navigation/NavPopularStores/reducers';
import navSeasonalFlyout from 'core/modules/Navigation/NavSeasonalFlyout/reducers';
import letterboxTOF from 'core/modules/LetterboxTOF/reducers';
import searchBar from 'core/modules/SearchBar/reducers';
import featuredDeals from 'core/modules/FeaturedDeals/reducers';
import topEarn from 'core/modules/TopEarn/reducers';
import topStores from 'core/modules/TopStores/reducers';
import featuredOffersLogo from 'core/modules/FeaturedOffersLogo/reducers';
import trendingAndSeasonalOffers from 'core/modules/TrendingAndSeasonalOffers/reducers';
import recentMemberOrders from 'core/modules/RecentMemberOrders/reducers';
import recentlyShopped from 'core/modules/RecentlyShopped/reducers';
import recommendedStores from 'core/modules/RecommendedStores/reducers';
import featuredStoresModal from 'core/modules/Modal/components/FeaturedStoresModal/reducers';
import popularCategories from 'core/modules/PopularCategories/reducers';
import becauseYouShopped from 'core/modules/BecauseYouShopped/reducers';
import emailOptInSubscribeModal from 'core/modules/Modal/components/EmailOptInSubscribeModal/reducers';
import emailOptInSuccessModal from 'core/modules/Modal/components/EmailOptInSuccessModal/reducers';
import featuredFavorites from 'core/modules/FeaturedFavorites/reducers';
import searchForm from 'core/modules/SearchForm/reducers';
import hybridSearchForm from 'core/modules/HybridSearchForm/reducers';
import seasonalFeatured from 'core/modules/SeasonalFeatured/reducers';
import storeBundles from 'core/modules/StoreBundles/reducers';
import offerOverlayLink from 'core/modules/OfferOverlayLink/reducers';
import onlineInStoreOfferOverlayLink from 'core/modules/OnlineInStoreOfferOverlayLink/reducers';
import onboardingFavorites from 'core/modules/Onboarding/components/Favorites/reducers';
import onboardingButton from 'core/modules/Onboarding/components/Button/reducers';
import deletedMemberModal from 'core/modules/Modal/components/DeletedMemberModal/reducers';
import instoreHiwModal from 'core/modules/Modal/components/InstoreHiwModal/reducers';
import instoreAllOffersMapModal from 'core/modules/Modal/components/InstoreAllOffersMapModal/reducers';
import infoBar from 'core/modules/InfoBar/reducers';
import infoBarMarketing from 'core/modules/InfoBarMarketing/reducers';
import recentStores from 'core/modules/RecentStores/reducers';
import favoritedStores from 'core/modules/FavoritedStores/reducers';
import dynamicRewardsPageContent from 'core/modules/DynamicRewardsPageContent/reducers';
import dynamicRewardsTabContent from 'core/modules/DynamicRewardsTabContent/reducers';
import marketingText from 'core/modules/MarketingText/reducers';
import simpleBannerList from 'core/modules/SimpleBannerList/reducers';
import featuredOffers502x272 from 'core/modules/FeaturedOffers502x272/reducers';
import featuredOffers150x150 from 'core/modules/FeaturedOffers150x150/reducers';
import featuredOffers120x90 from 'core/modules/FeaturedOffers120x90/reducers';
import dynamicMerchants from 'core/modules/DynamicMerchants/reducers';
import sponsoredOffers from 'core/modules/SponsoredOffers/reducers';
import sponsoredTextOffers from 'core/modules/SponsoredTextOffers/reducers';
import categoryMerchantsTable from 'core/modules/CategoryMerchantsTable/reducers';
import offerTable from 'core/modules/OfferTable/reducers';
import popularOffers from 'core/modules/PopularOffers/reducers';
import merchantExperience from 'core/modules/MerchantExperience/reducers';
import relatedStores from 'core/modules/RelatedStores/reducers';
import merchantTileList from 'core/modules/MerchantTileList/reducers';
import flyoutCategories from 'core/modules/FlyoutCategories/reducers';
import relatedCategoryFeaturedStores from 'core/modules/RelatedCategoryFeaturedStores/reducers';
import errorMessage from 'core/modules/ErrorMessage/reducers';
import bonusTiers from 'core/modules/BonusTiers/reducers';
import bonusTracker from 'core/modules/BonusTracker/reducers';
import bonusOffers from 'core/modules/BonusOffers/reducers';
import bonusOffersFlexPage from 'core/modules/BonusOffersFlexPage/reducers';
import productDetails from 'core/modules/ProductDetails/reducers';
import buttonLanding from 'core/modules/Button/ButtonLanding/reducers';
import productSearchResults from 'core/modules/ProductSearchResults/reducers';
import instoreOffers from 'core/modules/Instore/OffersSection/reducers';
import marketingBanner from 'core/modules/MarketingBanner/reducers';
import favoritesLandingMerchants from 'core/modules/FavoritesLanding/reducers';
import extraRewardsDeals from 'core/modules/ExtraRewardsDeals/reducers';
import contactUsInquiryOrder from 'core/modules/ContactUs/Dashboard/components/InquiryOrder/reducers';
import map from 'core/modules/Instore/Map/reducers';
import merchantsList from 'core/modules/MerchantsList/reducers';
import instoreAdOffers from 'core/modules/InstoreAdOffers/reducers';
import productList from 'core/modules/ProductList/reducers';
import inquiryUploadFile from 'core/modules/ContactUs/Dashboard/components/InquiryUploadFile/reducers';
import adBlockWarning from 'core/modules/AdBlockWarning/reducers';
import recentStoresFlyout from 'core/modules/RecentStoresFlyout/reducers';
import marketingMerchantsHome from 'core/modules/MarketingMerchantsHome/reducers';
import favoriteStores from 'core/modules/FavoriteStores/reducers';
import mediaQueries from 'core/modules/MediaQueries/reducers/mediaQueries';
import stickyFooter from 'core/modules/StickyFooter/reducers/stickyFooter';
import pickFavorites from 'core/modules/PickFavorites/reducers/pickFavorites';
import hiwVideoModal from 'core/modules/Modal/components/HiwVideoModal/reducers';
import hybridSearchResults from 'core/modules/HybridSearchResults/reducers';
import giftGuide from 'core/modules/GiftGuide/reducers';
import personalizedTopOffers from 'core/modules/PersonalizedTopOffers/reducers';
import offersMarketingFlyout from 'core/modules/OffersMarketingFlyout/reducers';
import trendingStores from 'core/modules/TrendingStores/reducers';
import buttonScrollingModal from 'core/modules/Modal/components/ButtonScrollingModal/reducers';
import creditCardMarketingBanner from 'core/modules/CreditCardMarketingBanner/reducers';
import sweeps from 'core/modules/Sweeps/reducers';
import productOverlayLink from 'core/modules/ProductOverlayLink/reducers';
import productDetailsModal from 'core/modules/Modal/components/ProductDetailsModal/reducers';
import holidayBorder from 'core/modules/HolidayBorder/reducers';
import headerHolidayImage from 'core/modules/Header/HeaderHolidayImage/reducers';
import profile from 'core/modules/Profile/reducers';
import earningsSnapshot from 'core/modules/EarningsSnapshot/reducers';
import referral from 'core/modules/Referral/reducers';

import { reducer as form } from 'redux-form';

export default {
  app,
  accountSummary,
  adBlockWarning,
  authentication,
  becauseYouShopped,
  bonusTiers,
  bonusTracker,
  bonusOffers,
  bonusOffersFlexPage,
  buttonLanding,
  buttonScrollingModal,
  buyOnlinePickupInstore,
  cardLinkOffer,
  categoryMerchantsTable,
  contactUsInquiryOrder,
  creditCardMarketingBanner,
  deletedMemberModal,
  dynamicMerchants,
  dynamicRewardsPageContent,
  dynamicRewardsTabContent,
  earningsSnapshot,
  emailOptInSubscribeModal,
  emailOptInSuccessModal,
  errorMessage,
  extraRewardsDeals,
  favorites,
  favoriteStores,
  featuredDeals,
  featuredFavorites,
  favoritedStores,
  featuredOffers120x90,
  featuredOffers150x150,
  featuredOffers502x272,
  featuredOffersLogo,
  featuredStoresModal,
  favoritesLandingMerchants,
  form,
  flyoutCategories,
  giftGuide,
  headerHolidayImage,
  hiwVideoModal,
  hybridQuickSearch,
  hybridSearchForm,
  hybridSearchResults,
  hybridStoresOfferModal,
  holidayBorder,
  instoreHiwModal,
  instoreAddNewCardModal,
  instoreAdOffers,
  instoreAllOffersMapModal,
  instoreCardsListModal,
  instoreManageCardsModal,
  instoreMultiLinkModal,
  instoreOffers,
  instoreOfferModal,
  instoreSMSNumberModal,
  infoBar,
  infoBarMarketing,
  letterboxTOF,
  marketingBanner,
  map,
  marketingMerchantsHome,
  marketingText,
  mediaQueries,
  merchantExperience,
  merchantLogos,
  merchantsList,
  merchantTileList,
  merchantOfferModal,
  modal,
  nav,
  navCategories,
  navPopularStores,
  navSeasonalFlyout,
  onboardingFavorites,
  onboardingButton,
  onlineInStoreOfferOverlayLink,
  offersMarketingFlyout,
  offerOverlayLink,
  offerTable,
  personalizedTopOffers,
  pickFavorites,
  popularCategories,
  popularOffers,
  productDetails,
  productList,
  productDetailsModal,
  productOverlayLink,
  productSearchResults,
  profile,
  quickSearch,
  recentlyShopped,
  recentMemberOrders,
  recentStores,
  recentStoresFlyout,
  recommendedStores,
  referral,
  relatedCategoryFeaturedStores,
  relatedStores,
  searchBar,
  seasonalFeatured,
  searchForm,
  simpleBannerList,
  sponsoredOffers,
  sponsoredTextOffers,
  stickyFooter,
  storeBundles,
  sweeps,
  inquiryUploadFile,
  topEarn,
  topStores,
  trendingAndSeasonalOffers,
  trendingStores,
};
