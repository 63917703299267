import { get } from 'lodash';
import UrlParse from 'url-parse';

export function getPageNameFromURL(urlString) {
  if (!urlString || urlString.length === 0) {
    return null;
  }

  // On some cases we may have the '/index.php?p=hiw' url where the page name is set in a 'p' parameter
  const { pathname, query: { p } } = new UrlParse(urlString, true);
  if (pathname.indexOf('index.php') > -1 && p) {
    return p;
  }

  // Remove all shashes prepending the pathname: '/home___.htm' => 'home____.htm'
  return pathname.replace(/^(.*[\\/])/, '')
    // Remove the last dot and everything after: 'home___.htm' => 'home____'
    .replace(/\.[^.]*$/, '')
    // Remove all trailing underscores: 'home____' => 'home'
    .replace(/_+$/, '');
}

export default function getPageName() {
  return get(window, 'initialState.app.page.name', getPageNameFromURL(window.location.pathname));
}
