/* eslint-disable camelcase */
import UrlParse from 'url-parse';
import { has, isNil } from 'lodash';
import { detectIncognitoMode } from 'core/utils/helpers/browser';
import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';

/*
Barry Lai:
Notes below are of understanding of canjs/C8 codebase requirements and MHP/VHP requirements as of 3/6/18
This is not a direct port of canjs (mnGa.js) functionality but product has requested MOP-88
"Make sure we are tracking all proper custom dimensions for page views (as we are in canjs codebase)"
Some dimensions are only sent in special cases or mnGa has special logic that doesn't make sense in react MHP/VHP.
Examples:
dimension_6 (click_id) is only sent during click interstitials
dimension_4 (merchant) is the merchant name but only used when showing a merchant overlay or on the ME page
While some of the dimensions like 13 (chan) is also only related to click, its implementation was easy enough to be
ported over anyway (but will be null, and subsequently not sent to ga).
These are the definitions of each dimension:
 1: 'organization',
 2: 'brand',
 3: 'member_id',
 4: 'merchant',
 5: 'page_name',
 6: 'click_id',
 7: 'server_id',
 8: 'subdomain',
 9: 'log_in_status',
 10: 'source',
 11: 'cont',
 12: 'med',
 13: 'chan',
 14: 'strm',
 15: 'cam',
 16: 'seg',
 17: 'isource',
 18: 'private_or_incognito',
Additionally, we have some custom page name mappings for things like the 'h' page (h_VHP or m_MHP for visitor or member
home page), sweeps_visitor/member_entered/member_not_entered.
Only the home____.htm page for mhp/vhp is customized at this time.
 */

const DEFAULT_VALUE_NOT_SET = '(not set)';

export const dimensionsDefaultValues = ({
  source: DEFAULT_VALUE_NOT_SET,
  click_id: DEFAULT_VALUE_NOT_SET,
  isource: DEFAULT_VALUE_NOT_SET,
  private_or_incognito: DEFAULT_VALUE_NOT_SET,
  cont: DEFAULT_VALUE_NOT_SET,
  med: DEFAULT_VALUE_NOT_SET,
  chan: DEFAULT_VALUE_NOT_SET,
  strm: DEFAULT_VALUE_NOT_SET,
  cam: DEFAULT_VALUE_NOT_SET,
  seg: DEFAULT_VALUE_NOT_SET,
});

export const setDefaultValuesToDimensions = dimensions => Object.entries(dimensions).reduce((acc, [key, value]) => {
  let dimensionValue;
  if ((isNil(value) || value === '') && !isNil(dimensionsDefaultValues[key])) {
    dimensionValue = dimensionsDefaultValues[key];
  } else {
    dimensionValue = value;
  }
  return { ...acc, [key]: dimensionValue };
}, {});

const getCustomHomePageName = (initialState) => {
  const { user } = initialState;
  const homePageQuerySuffix = window.innerWidth < 768 ? 'mobile' : 'desktop';
  return user.isIdentified ? `mhp_${homePageQuerySuffix}` : `vhp_${homePageQuerySuffix}`;
};

// This function is called after API response is received
export const getCustomSweepsPageName = (isUserIdentified, isSweepsActive, isUserOptedIn) => {
  if (!isUserIdentified || !isSweepsActive) {
    return 'sweeps_visitor';
  }

  return isUserOptedIn ? 'sweeps_member_entered' : 'sweeps_member_not_entered';
};

const customPageNames = {
  h: initialState => (getCustomHomePageName(initialState)),
  sweeps: getCustomSweepsPageName,
};

export const setInitialCustomDimensions = async (initialState) => {
  const {
    app: {
      org,
      brand,
      page,
      features,
    },
    user,
    env,
  } = initialState;

  const currentUrl = new UrlParse(window.location.href, true);
  const { query } = currentUrl;
  const { merchant } = getParamsFromSearch();
  const formattedMerchant = merchant ? `${merchant.charAt(0).toUpperCase()}${merchant.slice(1)}` : '';

  let customDimensions = {
    organization: `${org.name}.${org.id}`,
    brand: `${brand.name}.${brand.id}`,
    // The member_id property is sent as part of user_properties object now
    // member_id: user.isIdentified ? user.userIdHashed : DEFAULT_VALUE_NOT_SET,
    merchant: formattedMerchant || DEFAULT_VALUE_NOT_SET,
    page_name: page.name,
    click_id: features.clickId || DEFAULT_VALUE_NOT_SET,
    server_id: env.serverId,
    subdomain: currentUrl.host.slice(0, -env.rootDomain.length),
    log_in_status: user.isIdentified ? '1' : '0',
    source: query.source,
    cont: query.cont,
    med: query.med,
    chan: query.chan,
    strm: query.strm,
    cam: query.cam,
    seg: query.seg,
    isource: query.isource,
    private_or_incognito: await detectIncognitoMode(),
  };

  if (has(customPageNames, page.name)) {
    const customPageName = customPageNames[page.name](initialState);

    customDimensions.page = `/${customPageName}`;
    customDimensions.page_name = customPageName;
  }

  customDimensions = setDefaultValuesToDimensions(customDimensions);

  return customDimensions;
};

export const customDimensionsMap = {
  dimension1: 'organization',
  dimension2: 'brand',
  dimension3: 'member_id',
  dimension4: 'merchant',
  dimension5: 'page_name',
  dimension6: 'click_id',
  dimension7: 'server_id',
  dimension8: 'subdomain',
  dimension9: 'log_in_status',
  dimension10: 'source',
  dimension11: 'cont',
  dimension12: 'med',
  dimension13: 'chan',
  dimension14: 'strm',
  dimension15: 'cam',
  dimension16: 'seg',
  dimension17: 'isource',
  dimension18: 'private_or_incognito',
  dimension19: 'section_id',
};
