import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectHasNoRewardsEnabled } from 'core/selectors/app';

import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import RecentStores from 'core/modules/RecentStores/RecentStores';
import SimilarStores from 'core/modules/SimilarStores/SimilarStores';

import {
  selectExactQuickSearchMatchSimilarStores, selectExactQuickSearchMatchName,
} from 'core/modules/HybridQuickSearch/selectors';

export function RecentOrSimilarStoresMatches({ keywords, config, productGspMatches }) {
  const hasNoRewardsEnabled = useSelector(selectHasNoRewardsEnabled);
  const quickSearchExactMatchSimilarStores =
    useSelector(state => selectExactQuickSearchMatchSimilarStores(state, keywords, config));
  const quickSearchExactMatchName = useSelector(state => selectExactQuickSearchMatchName(state, keywords, config));

  if (hasNoRewardsEnabled && quickSearchExactMatchSimilarStores) {
    return (
      <SimilarStores
        merchants={quickSearchExactMatchSimilarStores}
        merchantMatchName={quickSearchExactMatchName}
      />
    );
  }

  if (!isEmpty(productGspMatches)) {
    return null;
  }

  return <RecentStores />;
}

export default (props) => <ComponentErrorBoundary><RecentOrSimilarStoresMatches {...props} /></ComponentErrorBoundary>;
