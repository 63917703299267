import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { OffersSection as orgConfig } from 'org/configs/modules.config';
import { INSTORE_OFFERS_GET_FAILURE, INSTORE_OFFERS_GET_SUCCESS } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: INSTORE_OFFERS_GET_SUCCESS,
        failure: INSTORE_OFFERS_GET_FAILURE,
        cache: { isEnabled: false, isPublishedData: false },
      },
      params: {
        active: 1,
      },
    },
  },
  // Curated instore offers on MHP
  h: {
    api: {
      params: {
        content_group_id: 20575,
        type: 'instore',
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
