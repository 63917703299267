import {
  select, takeEvery, put, call,
} from 'redux-saga/effects';
import { merge } from 'lodash';
import { isErrorResponse } from 'core/utils/apis/base';
import bonusApi from 'core/utils/apis/bonus';
import { selectBonusConfig } from 'core/selectors/services';
import { selectBrandId } from 'core/selectors/app';
import { selectIsRavenCookied } from 'core/selectors/env';
import { generateFailureAction, generateSuccessAction } from 'core/actions/apis';
import * as actions from 'core/actions/bonus';
import bonusRequestor from 'core/utils/apis/bonusRequestor';
import responseParsers from 'core/utils/apis/responseParsers';

function* makeBonusApiRequest({ caller, requestArgList, defaultValue }, action = {}) {
  const state = yield select(state => state);
  const response = yield call([bonusRequestor, bonusRequestor.getAPIResponse], {
    caller,
    requestArgList,
    shouldParseResponse: true,
    action,
    state,
  });

  if (!isErrorResponse(response)) {
    yield put(generateSuccessAction(action, response));
  } else {
    // Todo - Autodetect a 498 error and replay the original action after dropping the authorization token
    //        Keep in mind that we need to avoid infinite loops
    yield put(generateFailureAction(action, response, defaultValue));
  }
}

/**
 * @param {string} actionType
 * @param {Function} caller
 * @param {array} requestArgList
 * @param {*} defaultValue
 */
const takeEveryBonusApi = (actionType, caller, requestArgList, defaultValue) =>
  takeEvery(actionType, makeBonusApiRequest, { caller: caller.bind(bonusApi), requestArgList, defaultValue });

export function* getBonusApiInstance() {
  const config = yield select(selectBonusConfig);
  const brandId = yield select(selectBrandId);
  const isRavenCookied = yield select(selectIsRavenCookied);
  const bonusConfig = merge({}, config, {
    headers: {
      ...isRavenCookied && { 'X-MNRaven': 1 },
    },
  });

  bonusApi.setParams({ bonusConfig, brandId });
}

function* returnBonusApiMock(action = {}) {
  const state = yield select(state => state);
  const mock = action.data || {};
  // Manually run parsing of mock response before pass it to reducers
  const parsedMock = bonusRequestor.parseResponse({
    response: mock.response,
    parser: responseParsers.bonusParser,
    action,
    state,
  });
  yield put(generateSuccessAction(action, {}, parsedMock));
}

/**
 * Watch for any an all bonus requests
 */
export default function* bonusSaga() {
  yield getBonusApiInstance();

  yield takeEveryBonusApi(actions.BONUS_GET_INFORMATION, bonusApi.getBonus.bind(bonusApi));
  yield takeEveryBonusApi(actions.BONUS_GET_INFORMATION_MEMBER_RELATED_EXPIRED,
    bonusApi.getExpiredBonus.bind(bonusApi));
  yield takeEveryBonusApi(actions.BONUS_GET_INFORMATION_MEMBER_RELATED, bonusApi.getMemberRelatedBonus.bind(bonusApi));
  yield takeEvery(actions.BONUS_GET_INFORMATION_MOCK, returnBonusApiMock);
}
