import React from 'react';
import FavoriteIcon from 'core/modules/FavoriteIcon/FavoriteIcon';
import MerchantRebate from 'core/modules/MerchantRebate/MerchantRebate';
import PremierMerchantRebate from 'core/modules/PremierMerchantRebate/PremierMerchantRebate';

import { highlightMatch } from '../../utils/highlightMatch';

export function OnlineMatches({
    localConfig, onlineMatches, keywords, localMerchantClickHandler, isPopUpView, instoreMatches, getTitle,
}) {
  const {
    showFavoriteIcon, directMerchantName, rebateOptions, isPremierRebate,
  } = localConfig;

  if (onlineMatches.length === 0) {
    return null;
  }

  // Pop up view: if in-store is included show a max of 2 stores
  const onlineMatchesToShow = (isPopUpView && instoreMatches.length) ? onlineMatches.slice(0, 2) : onlineMatches;

  return (
    <>
      <div className="mn_quickSearchItemsWrapper">
        {onlineMatchesToShow.map((match) => {
          const { merchant, matchText } = match;
          const {
            id, name, href, rebate, showRebate, isDeactivated, isDirect,
          } = merchant;
          const highlighted = highlightMatch(name, matchText);
          return (
            <div className="mn_quickSearchItem" key={`${name}-online`}>
              {showFavoriteIcon && <FavoriteIcon merchantId={id} merchantName={name} />}
              <a
                href={`${href}&keywords=${keywords}`}
                key={`${name}-online`}
                className="mn_quickSearchMatch mn_onlineMatch"
                data-merchant-id={id}
                data-merchant-name={name}
                data-merchant-type="online"
                onClick={localMerchantClickHandler}
                title={getTitle(name)}
              >
                <div className="mn_quickSearchContent">
                  <span className="mn_matchText" key={id}>{highlighted}</span>
                  <span className="mn_dash">&nbsp;-&nbsp;</span>
                  {(showRebate || isDeactivated) &&
                    (isPremierRebate ?
                      <PremierMerchantRebate {...rebate} {...rebateOptions} /> :
                      <MerchantRebate {...rebate} {...rebateOptions} />
                    )
                  }
                </div>
                {isDirect && <span className="mn_directMerchantName">{directMerchantName}</span>}
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
}

  export default OnlineMatches;
