import { get } from 'lodash';
import {
  INSTORE_OFFER_MODAL_CLEAR,
  INSTORE_OFFER_MODAL_TRIGGER,
} from '../actions/types';

const initialState = {};

const instoreOfferModal = (state = initialState, action) => {
  switch (action.type) {
    case INSTORE_OFFER_MODAL_TRIGGER: {
      return {
        ...state,
        offerId: get(action, 'data.offerId', null),
        showError: get(action, 'data.showError', false),
        type: get(action, 'data.type', ''),
        namespace: get(action, 'data.namespace', ''),
      };
    }
    case INSTORE_OFFER_MODAL_CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
};

export default instoreOfferModal;
