import { createSelector } from 'reselect';
import { addCommas, stripNonDecimals } from 'core/utils/helpers/numbers';
import { isEmpty } from 'lodash';

const createLifetimeEarningFormatted = (lifetimeEarningValue) => {
  if (lifetimeEarningValue === undefined || lifetimeEarningValue === null) {
    return null;
  }
  return addCommas(lifetimeEarningValue);
};

const createLifeTimeEarningValue = (lifetimeEarning) => {
  if (lifetimeEarning === undefined || lifetimeEarning === null) {
    return null;
  }
  return parseInt(stripNonDecimals(lifetimeEarning), 10);
};

export const selectAccountSummary = (state) => {
  const { data } = state.accountSummary;
  if (!isEmpty(data)) {
    data.lifetimeEarningValue = createLifeTimeEarningValue(data.lifetimeEarning);
    data.lifetimeEarningFormatted = createLifetimeEarningFormatted(data.lifetimeEarningValue);
  }
  return state.accountSummary;
};

export const selectAccountSummaryConfig = createSelector(
  selectAccountSummary,
  accountSummary => accountSummary.config,
);

export const selectAccountSummaryIsLoading = createSelector(
  selectAccountSummary,
  accountSummary => accountSummary.isLoading,
);

export const selectAccountSummaryIsLoaded = createSelector(
  selectAccountSummary,
  accountSummary => accountSummary.isLoaded,
);

export const selectAccountSummaryData = createSelector(
  selectAccountSummary,
  accountSummary => accountSummary.data,
);

export const selectAccountSummaryLifetimeEarning =
  createSelector(selectAccountSummaryData, (data = {}) => data.lifetimeEarning);
export const selectAccountSummaryYearToDateEarning =
  createSelector(selectAccountSummaryData, (data = {}) => data.yearToDateEarning);
export const selectAccountSummaryLifetimeEarningValue =
  createSelector(selectAccountSummaryData, (data = {}) => data.lifetimeEarningValue);
export const selectAccountSummaryLifetimeEarningFormatted =
  createSelector(selectAccountSummaryData, (data = {}) => data.lifetimeEarningFormatted);
