import { merge } from 'lodash';
import parseClickUrl from './commonParsers/clickUrlParser';
import makeImageUrlSecure from './commonParsers/makeImageUrlSecure';
import parseRebate from './commonParsers/rebateParser';

export default (response, state) =>
  response.map(product =>
    merge(product, {
      clickUrl: parseClickUrl(product.clickUrl, state),
      images: product.images.map(makeImageUrlSecure),
      mainImage: makeImageUrlSecure(product.images[0]),
      rebate: parseRebate(product.rebate, product.tiers, state),
    }));
