import { shuffle } from 'lodash';
import { makeArrayEvenLength } from 'core/utils/array';
import { TOP_EARN_GET_SUCCESS, TOP_EARN_GET_FAILURE } from '../actions/types';
import topEarnConfig from '../configs';

/** @type {{config: object, merchants: array.<object>}} */
const initialState = {
  config: topEarnConfig,
  merchants: [],
  isLoading: false,
  isLoaded: false,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const topEarn = (state = initialState, action) => {
  switch (action.type) {
    case TOP_EARN_GET_SUCCESS: {
      const placements = action.payload.response;
      const merchants = placements.map(placement => placement.merchant);
      return {
        ...state,
        merchants: shuffle(makeArrayEvenLength(merchants)),
        isLoading: false,
        isLoaded: true,
      };
    }
    case TOP_EARN_GET_FAILURE: {
      return {
        ...state,
        merchants: [],
        isLoading: false,
        isLoaded: true,
      }; // we'd have to decide what to do with error state
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === TOP_EARN_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default topEarn;
