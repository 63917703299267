import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { merchantExperience as orgConfig } from 'org/configs/modules.config';
import { getId } from 'core/utils/analytics/sectionInfo';

import { MERCHANT_EXPERIENCE_GET_SUCCESS, MERCHANT_EXPERIENCE_GET_FAILURE } from '../actions/types';

export const defaultConfig = {
  defaults: {
    api: {
      params: {
        with_offers: 1,
        with_instore: 0,
        section_id: getId('merchant-experience'),
      },
      options: {
        success: MERCHANT_EXPERIENCE_GET_SUCCESS,
        failure: MERCHANT_EXPERIENCE_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    sid: 9074,
    useAddToFavorite: true,
    showHeaderWithStoreCount: false,
    termsTitle: 'Terms',
    currency: 'miles',
    hideMerchantTerms: false,
    showPermanentTooltip: true,
    moreStoresSuspendedHeader: 'Looking for similar stores?',
    moreStoresSuspendedMessage: {
      withStoreCount: 'Visit the All stores page and browse from over %storeCount% stores.',
      withoutStoreCount: 'View all participating stores here.',
    },
    moreProductSuspendedHeader: 'Not looking for a store?',
    rebateOptions: {
      showAdditionalElevationPrefix: true,
      hideRebateSuffix: true,
      tiered: {
        rebateAdditionalPrefix: 'Shop and earn',
      },
    },
    programTerms: {
      loggedIn: null,
      loggedOut: null,
    },
    directMerchantName: '',
    directMerchantCTA: '',
    directMerchantDescription: '',
  },
};

export const merchantExperienceConfig = mergeModuleConfigs(defaultConfig, orgConfig);
