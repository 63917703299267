import {
  FAVORITE_STORES_GET_SUCCESS,
  FAVORITE_STORES_GET_FAILURE,
  CLEAR_FAVORITE_STORES_LIST,
 } from '../actions/types';
import favoriteStoresConfig from '../configs';

const initialState = {
  config: favoriteStoresConfig,
  merchants: [],
};

const filterResponse = (response) => (
  response.filter((merchant) => merchant.type === 'online')
);

const favoriteStores = (state = initialState, action) => {
  switch (action.type) {
    case FAVORITE_STORES_GET_SUCCESS: {
      const merchants = action.payload.response;
      return {
        ...state,
        merchants: filterResponse(merchants),
      };
    }
    case FAVORITE_STORES_GET_FAILURE: {
      return state;
    }
    case CLEAR_FAVORITE_STORES_LIST: {
      return {
        ...state,
        merchants: [],
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === FAVORITE_STORES_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default favoriteStores;
