import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMemberAccountSummary } from 'core/actions/ocapi';
import { selectUserIsIdentified } from 'core/selectors/user';
import {
  selectAccountSummaryData,
  selectAccountSummaryConfig,
  selectAccountSummaryIsLoading,
  selectAccountSummaryIsLoaded,
} from 'core/modules/AccountSummary/selectors';

export const useGetAccountSummary = () => {
  const dispatch = useDispatch();
  const config = useSelector(selectAccountSummaryConfig);
  const isLoading = useSelector(selectAccountSummaryIsLoading);
  const isLoaded = useSelector(selectAccountSummaryIsLoaded);
  const accountSummaryData = useSelector(selectAccountSummaryData);
  const userIsIdentified = useSelector(selectUserIsIdentified);

  useEffect(() => {
    if (userIsIdentified && !isLoading && !isLoaded) {
      const { api: { params, options } } = config;
      dispatch(fetchMemberAccountSummary(params, options));
    }
  }, []);

  return {
    accountSummaryData,
    isLoading,
    isLoaded,
  };
};
