import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { selectPageName } from 'core/selectors/app';

// A syntax sugar for a Loadable plugin call
// Note: the module path system.import can't be fully set as an expression
// so the ./pages folder is hardcoded here to define the correct context for webpack
const LoadableWrapper = pageComponentPath => Loadable({
  loader: () => import(`./pages${pageComponentPath}`),
  loading: () => (null),
});

// Note: pageComponentPath should be related to the ./pages folder
const routesConfig = [
  { name: 'home', route: '(/|/h____.htm)', pageComponentPath: LoadableWrapper('/Home') },
  { name: 'sample', route: '/sample____.htm', pageComponentPath: LoadableWrapper('/Sample') },
  { name: 'style', route: '/style____.htm', pageComponentPath: LoadableWrapper('/Style') },
  { name: 'hiw', route: '/hiw____.htm', pageComponentPath: LoadableWrapper('/Hiw') },
  { name: 'faq', route: '/faq____.htm', pageComponentPath: LoadableWrapper('/Faq') },
  { name: 'dynamic rewards page', route: '/p/:dynamicPage', pageComponentPath: LoadableWrapper('/DynamicRewards') },
  { name: 'all offers', route: '/sp____.htm', pageComponentPath: LoadableWrapper('/AllOffers') },
  { name: 'free shipping', route: '/(fs____.*).htm', pageComponentPath: LoadableWrapper('/FreeShipping') },
  { name: 'terms', route: '/terms____.htm', pageComponentPath: LoadableWrapper('/Terms') },
  { name: 'privacy', route: '/privacy____.htm', pageComponentPath: LoadableWrapper('/Privacy') },
  { name: 'eula', route: '/eula____.htm', pageComponentPath: LoadableWrapper('/Eula') },
  {
    name: 'email unsubscribe',
    route: '/:unsubscribe?/eu____.htm',
    pageComponentPath: LoadableWrapper('/EmailUnsubscribe'),
  },
  { name: 'merchant experience', route: '/me____.htm', pageComponentPath: LoadableWrapper('/MerchantExperience') },
  { name: 'no results', route: '/nr____.htm', pageComponentPath: LoadableWrapper('/NoResults') },
  { name: 'error', route: '/e____.htm', pageComponentPath: LoadableWrapper('/Error') },
  { name: 'product details', route: '/pd____.htm', pageComponentPath: LoadableWrapper('/ProductDetails') },
  { name: 'product comparison', route: '/pc____.htm', pageComponentPath: LoadableWrapper('/ProductDetails') },
  {
    name: 'category',
    route: '/:categoryName/bc_:categoryId*___.htm',
    pageComponentPath: LoadableWrapper('/CategoryPage'),
  },
  { name: 'button', route: '/button', pageComponentPath: LoadableWrapper('/ButtonLanding') },
  { name: 'button-uninstall', route: '/button/uninstall', pageComponentPath: LoadableWrapper('/ButtonUninstall') },
  { name: 'product search results', route: '/sr____.htm', pageComponentPath: LoadableWrapper('/ProductSearchResults') },
  { name: 'acq', route: '(/acq____.htm|/signin)', pageComponentPath: LoadableWrapper('/Acquisition') },
  { name: 'instore', route: '/instore', pageComponentPath: LoadableWrapper('/Instore') },
  { name: 'shopWifi', route: '/shopWifi____.htm', pageComponentPath: LoadableWrapper('/ShopWifi') },
  { name: 'favorites', route: '/favorites____.htm', pageComponentPath: LoadableWrapper('/Favorites') },
  { name: 'sweeps', route: '/sweeps____.htm', pageComponentPath: LoadableWrapper('/Sweeps') },
  { name: 'sweeps rules', route: '/sweepsrules____.htm', pageComponentPath: LoadableWrapper('/SweepsRules') },
  { name: 'foryou', route: '/foryou____.htm', pageComponentPath: LoadableWrapper('/ForYou') },
  { name: 'contact', route: '/contact____.htm', pageComponentPath: LoadableWrapper('/ContactUs') },
  { name: 'All Stores', route: '/b____.htm', pageComponentPath: LoadableWrapper('/Stores') },
  { name: 'Profile', route: '/ai____.htm', pageComponentPath: LoadableWrapper('/Profile') },
  { name: 'hybrid search results', route: '/hsr____.htm', pageComponentPath: LoadableWrapper('/HybridSearchResults') },
  { name: 'stores search results', route: '/msr____.htm', pageComponentPath: LoadableWrapper('/StoresSearchResults') },
  { name: 'coupon search results', route: '/csr____.htm', pageComponentPath: LoadableWrapper('/CouponSearchResults') },
  {
    name: 'button-safari-tutorial',
    route: '/button/safari/tutorial',
    pageComponentPath: LoadableWrapper('/SafariButtonInstallTutorial'),
  },
];

function Routes() {
  const pageName = useSelector(selectPageName);
  return (
    <Switch>
      {pageName === 'e' && <Route component={LoadableWrapper('/Error')} />}
      {routesConfig.map(({ name, route, pageComponentPath }) =>
        <Route key={name} exact path={route} component={pageComponentPath} />)}
      <Route component={LoadableWrapper('/Error')} />
    </Switch>
  );
}

export default Routes;
