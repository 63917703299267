const DEFAULT_CACHE_NAME = 'cache';

export default function CacheStorage(cacheName = DEFAULT_CACHE_NAME) {
  const debugMode = false;
  const isSupported = checkIsSupported();

  function checkIsSupported() {
    const debugPrefix = 'checkIsSupported';
    debugLog(`${debugPrefix} :: Checking browser support`);

    if ('caches' in window) {
      debugLog(`${debugPrefix} :: IS supported in browser`);
      return true;
    }
    debugLog(`${debugPrefix} :: IS NOT supported in browser`);
    return false;
  }

  function debugLog(...args) {
    const consoleArgs = [...args];

    if (!debugMode) {
      return;
    }
    const debugPrefix = '[DEBUG]';

    if (consoleArgs.length === 0) {
      console.log(`${debugPrefix} ---`);
    }

    if (typeof consoleArgs[0] === 'string') {
      consoleArgs[0] = `${debugPrefix} Cache Storage - ${cacheName} cache :: ${consoleArgs[0]}`;
    }

    console.log(...consoleArgs);
  }

  this.setItem = async (key, value, options = {}) => {
    if (!isSupported) return null;

    const debugPrefix = `setItem(${key})`;
    debugLog(`${debugPrefix} :: Trying to set item with value`, value);

    const { expirationTimestamp } = options;
    const cacheEntry = {
      value,
      expirationTimestamp,
      _mncached: true,
    };

    try {
      const cache = await caches.open(cacheName);
      await cache.put(key, new Response(JSON.stringify(cacheEntry)));
      debugLog(`${debugPrefix} :: Item written to cache`);
      return true;
    } catch (e) {
      debugLog(`${debugPrefix} :: Error writing to cache`, e);
      return false;
    }
  };

  this.getItem = async (key) => {
    if (!isSupported) return null;

    const debugPrefix = `getItem(${key})`;
    debugLog(`${debugPrefix} :: Checking for cached entry`);

    try {
      const cache = await caches.open(cacheName);
      const match = await cache.match(key);

      if (!match) {
        debugLog(`${debugPrefix} :: No cached entry match`);
        return null;
      }

      debugLog(`${debugPrefix} :: Found item - parsing as json!`);
      const cacheHit = await match.json();

      if (cacheHit.expirationTimestamp) {
        const cacheExpirationTimeStamp = new Date(cacheHit.expirationTimestamp).getTime();
        const timeNow = Date.now();

        if (cacheExpirationTimeStamp < timeNow) {
          await cache.delete(key);
          debugLog(`${debugPrefix} :: Removed item because it expired on ${cacheHit.expirationTimestamp}`);
          return null;
        }
      }

      debugLog(`${debugPrefix} :: Returning cache item with value:`, cacheHit);
      return cacheHit.value;
    } catch (e) {
      debugLog(`${debugPrefix} :: Cache exception`, e);
      return null;
    }
  };

  this.removeItem = async (key) => {
    if (!isSupported) return null;

    const debugPrefix = `removeItem(${key})`;
    debugLog(`${debugPrefix} :: Trying to remove item`);

    try {
      const cache = await caches.open(cacheName);
      const isItemRemoved = await cache.delete(key);

      isItemRemoved
        ? debugLog(`${debugPrefix} :: Item is removed`)
        : debugLog(`${debugPrefix} :: Item is not found in cache`);
      return isItemRemoved;
    } catch (e) {
      debugLog(`${debugPrefix} :: Cache exception`, e);
      return false;
    }
  };

  this.clear = async () => {
    if (!isSupported) return null;

    const debugPrefix = 'clear';
    debugLog(`${debugPrefix} :: Trying to clear cache`);

    try {
      await caches.delete(cacheName);
      debugLog(`${debugPrefix} :: Cleared cache`);
      return true;
    } catch (e) {
      debugLog(`${debugPrefix} :: Cache exception`, e);
      return false;
    }
  };

  this.clearPublishedData = async () => {
    if (!isSupported) return null;

    const debugPrefix = 'clearPublishedData';
    debugLog(`${debugPrefix} :: Trying to remove published items from cache`);

    try {
      const cache = await caches.open(cacheName);
      const keys = await cache.keys();

      await Promise.all(keys.map((key) => {
        if (key.url.includes('##pubID')) {
         return cache.delete(key);
        }
        return null;
      }));
      debugLog(`${debugPrefix} :: Removed published items from cache`);
      return true;
    } catch (e) {
      debugLog(`${debugPrefix} :: Cache exception`, e);
      return false;
    }
  };
}

export const cacheStorageCache = new CacheStorage('mallData');
