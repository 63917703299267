import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import FavoriteIcon from 'core/modules/FavoriteIcon/FavoriteIcon';
import PremierMerchantRebate from 'core/modules/PremierMerchantRebate/PremierMerchantRebate';

import { selectSiteUrl } from 'core/selectors/app';
import { selectUserIsIdentified } from 'core/selectors/user';
import { highlightMatch } from '../../utils/highlightMatch';

export function OnlineMatches({
  config, onlineMatches, keywords, isPopUpView, instoreMatches, merchantClickHandler,
}) {
  const { showFavoriteIcon, rebateOptions, directMerchantName } = config;
  const siteUrl = useSelector(selectSiteUrl);
  const isUserIdentified = useSelector(selectUserIsIdentified);

  if (isEmpty(onlineMatches)) {
    return null;
  }

  // Pop up view: if in-store is included show a max of 2 stores
  const onlineMatchesToShow = (isPopUpView && !isEmpty(instoreMatches)) ? onlineMatches.slice(0, 2) : onlineMatches;

  const quickSearcMatchClassName =
    `mn_quickSearchMatch mn_quickSearchMerchantMatch ${isUserIdentified ? '' : 'mn_isAnonymous'}`;

  const handleMerchantClick = (event, name) => {
    merchantClickHandler(name, 'online');
  };

  return (
    <div className="mn_quickSearchWrap mn_quickSearchOnlineWrap">
      <div className="mn_quickSearchItems">
        {onlineMatchesToShow.map((match) => {
          const { merchant, matchText } = match;
          const {
            id, name, href, rebate, showRebate, isDeactivated, isDirect,
          } = merchant;
          const highlighted = highlightMatch(name, matchText);
          return (
            <div className="mn_quickSearchItem" key={`${name}-online`}>
              <a
                href={`${href}&keywords=${keywords}`}
                key={`${name}-online`}
                className={quickSearcMatchClassName}
                onClick={(event) => handleMerchantClick(event, name)}
                title={`Opens merchant detail page for ${name}`}
              >
                <div className="mn_quickSearchMatchHighlight">
                  {showFavoriteIcon && <FavoriteIcon merchantId={id} merchantName={name} />}
                  <span className="mn_matchText" key={id}>{highlighted}</span>
                  {(showRebate || isDeactivated) && <PremierMerchantRebate {...rebate} {...rebateOptions} />}
                </div>
                {isDirect && directMerchantName && <div className="mn_directMerchantPill">{directMerchantName}</div>}
              </a>
            </div>
          );
        })}
      </div>
      <a
        className="mn_quickSearchLink"
        href={`${siteUrl}/msr____.htm?keywords=${keywords}`}
      >
        View all store results
      </a>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><OnlineMatches {...props} /></ComponentErrorBoundary>;
