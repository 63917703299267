import {
  HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_GET_SUCCESS, HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_GET_FAILURE,
  HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_CLEAR,
  } from '../actions/types';

  const initialState = {
    placements: [],
    isLoading: false,
    isLoaded: false,
  };

const checkHasExcludedLanguage = (str) => {
  // Exclude any Product Search Typeahead results
  // where the category returned by GSP contains the word "Rakuten"
  const EXCLUDED_LANGUAGE = 'rakuten';

  return str.toLowerCase().includes(EXCLUDED_LANGUAGE);
};

const parseResponse = ({ suggestions = [], cm = {} }) => (
  suggestions
    .map(({ terms = '', cid = [] }) => {
      const term = terms;
      const categoryId = cid[0];
      const categoryName = categoryId ? cm[categoryId] : '';

      if (!term || !categoryId || !categoryName) {
        return null;
      }

      if (checkHasExcludedLanguage(categoryName)) {
        return null;
      }

      return ({ term, categoryId, categoryName });
    })
    .filter(suggestion => !!suggestion)
);

const gspProducts = (state = initialState, action) => {
    switch (action.type) {
      case HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_GET_SUCCESS: {
        return {
          ...state,
          placements: parseResponse(action.payload),
          isLoading: false,
          isLoaded: true,
        };
      }
      case HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_GET_FAILURE: {
        return {
          ...state,
          placements: [],
          isLoading: false,
          isLoaded: true,
        };
      }
      case HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_CLEAR: {
        return {
          ...initialState,
        };
      }
      default: {
        const { options = {} } = action;
        if (options.success === HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_GET_SUCCESS) {
          return {
            ...state,
            isLoading: true,
            isLoaded: false,
          };
        }
      }
    }

    return state;
  };

  export default gspProducts;
