import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import InstoreMerchantRebate from 'core/modules/InstoreMerchantRebate/InstoreMerchantRebate';
import { redirectToTheTargetPage } from 'core/utils/helpers/url';
import { selectSiteUrl } from 'core/selectors/app';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';

import { highlightMatch } from '../../utils/highlightMatch';

export function InstoreMatches({
  config, instoreMatches, isPopUpView, merchantClickHandler,
}) {
  const siteUrl = useSelector(selectSiteUrl);
  const isMobileMQ = useSelector(selectIsMobileMQ);

  const { instoreIconImage } = config;

  const clickOfferHandler = (event, { offerId }, name) => {
    event.preventDefault();
    merchantClickHandler(name, 'instore');

    // CanJs and React use different url params to show the overlay
    const redirectUrl = isMobileMQ
      ? `instore#modalType=mt_instoreOffer&offerId=${offerId}`
      : `instore#lightboxType=instoreLinkCard&checkForFirstTimeEnrollCard=false&offerId=${offerId}`;

    redirectToTheTargetPage(siteUrl, redirectUrl);
  };

  if (isEmpty(instoreMatches)) {
    return null;
  }

  // Pop up view: show a maximum of 1 in-store offer
  const instoreMatchesToShow = isPopUpView ? instoreMatches.slice(0, 1) : instoreMatches;

  return (
    <div className="mn_quickSearchWrap mn_quickSearchInstoreWrap">
      <h3 className="mn_quickSearchTitle">In-store offers</h3>
      <div className="mn_quickSearchItems">
        {instoreMatchesToShow.map((match) => {
          const { merchant, matchText } = match;
          const {
            id, name, offers = [], href,
          } = merchant;

          const instoreOffer = offers[0];
          const highlighted = highlightMatch(name, matchText);

          return (
            <div className="mn_quickSearchItem" key={`${name}-${instoreOffer.offerId}-instore`}>
              <a
                href={href}
                className="mn_quickSearchMatch mn_quickSearchMerchantMatch"
                onClick={(e) => clickOfferHandler(e, instoreOffer, name)}
                title={`Opens in-store offer details for ${name}`}
              >
                <div className="mn_quickSearchMatchHighlight">
                  <div className="mn_instoreIcon"><img src={instoreIconImage} alt="" /></div>
                  <span className="mn_matchText" key={id}>{highlighted}</span>
                  <InstoreMerchantRebate offer={instoreOffer} showInstoreRebatePrefix={false} />
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><InstoreMatches {...props} /></ComponentErrorBoundary>;
