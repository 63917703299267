import React from 'react';
import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { getId } from 'core/utils/analytics/sectionInfo';
import { topStores as orgConfig } from 'org/configs/modules.config';
import { CACHE_STORAGE } from 'core/utils/cache/base';
import { TOP_STORES_GET_SUCCESS, TOP_STORES_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        section_id: getId('top-merchants'),
        content_type_id: 55,
        with_offers: 0,
        sort_by: 'random',
        fields: [
          'merchant.logoUrls',
          'merchant.showRebate',
          'merchant.rebate',
          'merchant.id',
          'merchant.name',
          'merchant.clickUrl',
        ].join(','),
      },
      options: {
        success: TOP_STORES_GET_SUCCESS,
        failure: TOP_STORES_GET_FAILURE,
        cache: {
          isEnabled: true, isPublishedData: true, type: CACHE_STORAGE,
        },
      },
    },
    secTitle: 'Top stores',
    secTitlePill: undefined,
    useAddToFavorite: true,
    useCarousel: false,
    useCarouselOnMediaQueriesOnly: [],
    useSliderCounterOnMediaQueriesOnly: [],
    getViewAllLinkText: () => (<>View all stores</>),
    viewAllLinkCTAText: 'See all offers',
    getUseSmallerIcon: (isMobileMQ) => !isMobileMQ,
    getShouldClientUseExpandableView: () => false,
    maxMerchants: 10,
    tagHelper: () => {},
    getViewAllLinkUrl: (siteUrl) => `${siteUrl}/sp____.htm`,
    slick: {
      arrows: true,
      centerMode: false,
      infinite: false,
      swipeToSlide: true,
      swipe: true,
      dots: false,
      slidesToShow: 10,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1460,
          settings: { slidesToShow: 9 },
        },
        {
          breakpoint: 1300,
          settings: { slidesToShow: 8 },
        },
        {
          breakpoint: 1180,
          settings: { slidesToShow: 7 },
        },
        {
          breakpoint: 1023,
          settings: { arrows: false, dots: true, slidesToShow: 6 },
        },
        {
          breakpoint: 890,
          settings: { arrows: false, dots: true, slidesToShow: 5 },
        },
        {
          breakpoint: 720,
          settings: { arrows: false, dots: true, slidesToShow: 4 },
        },
        {
          breakpoint: 580,
          settings: { arrows: false, dots: true, slidesToShow: 3 },
        },
        {
          breakpoint: 440,
          settings: { arrows: false, dots: true, slidesToShow: 2 },
        },
      ],
    },
  },
  h: {
    getShouldClientUseExpandableView: (isUserIdentified, isMobileMQ) => (isUserIdentified && isMobileMQ),
    minMerchantsExpanadbleView: 4,
    maxMerchantsExpanadbleView: 10,
    api: {
      params: {
        section_id: getId('store-tiles'),
        content_group_id: null,
      },
      logged_in_params: {
        offer_tag: 'top-stores',
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
