import {
  INSTORE_ADD_CARD_ADDED,
  INSTORE_ADD_CARD_FAILURE, INSTORE_ADD_CARD_ON_CLOSE,
  INSTORE_ADD_CARD_POST,
  INSTORE_ADD_CARD_VIEW,
} from '../actions/types';

export const instoreAddCardActionEvents = {
  [INSTORE_ADD_CARD_VIEW]: ({ data }) => ({
      category: 'In-store',
      action: 'Initiated add card process',
      label: data,
    }),
  [INSTORE_ADD_CARD_ADDED]: () => ({
      category: 'In-store',
      action: 'Added card',
    }),
  [INSTORE_ADD_CARD_POST]: ({ data }) => ({
      category: 'In-store',
      action: 'Post add card window event received',
      label: data,
    }),
  [INSTORE_ADD_CARD_FAILURE]: ({ data }) => ({
      category: 'In-store',
      action: 'Add card error post submit',
      label: data ? '' : 'First onboarding',
    }),
  [INSTORE_ADD_CARD_ON_CLOSE]: ({ data }) => ({
    category: 'In-store',
    action: 'Closed add card prompt',
    label: data ? '' : 'First onboarding',
  }),
};
