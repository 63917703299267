import { merge } from 'lodash';
import { OFFER_OVERLAY_LINK_GET_SUCCESS, OFFER_OVERLAY_LINK_GET_FAILURE } from '../actions/types';
import config from '../configs';

/** @type {{config: object, placements: array.<object>}} */
const initialState = {
  config,
  associatedDealOfferIds: {},
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const offerOverlayLink = (state = initialState, action) => {
  switch (action.type) {
    case OFFER_OVERLAY_LINK_GET_SUCCESS: {
      return {
        ...state,
        associatedDealOfferIds: merge(
          {},
          state.associatedDealOfferIds,
          { [action.context.params.deal_id]: (action.payload.response[0].id) },
        ),
      };
    }
    case OFFER_OVERLAY_LINK_GET_FAILURE: {
      return state; // we'd have to decide what to do with error state
    }
    default: {
      return state;
    }
  }
};

export default offerOverlayLink;
