import {
  QUICK_SEARCH_GSP_SUGGESTIONS_GET_SUCCESS,
  QUICK_SEARCH_GSP_SUGGESTIONS_GET_FAILURE,
  } from '../actions/types';
  import config from '../configs';

  const initialState = {
    config,
    gspSuggestions: [],
  };

const parseResponse = (payload) => payload?.response?.docs || [];

const quickSearchSuggestionGSP = (state = initialState, action) => {
    switch (action.type) {
      case QUICK_SEARCH_GSP_SUGGESTIONS_GET_SUCCESS: {
        return {
          ...state,
          gspSuggestions: parseResponse(action.payload),
        };
      }
      case QUICK_SEARCH_GSP_SUGGESTIONS_GET_FAILURE: {
        return {
          ...state,
          gspSuggestions: [],
        };
      }
      default: {
        return state;
      }
    }
  };

  export default quickSearchSuggestionGSP;
