import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { instoreAdConfig as orgConfig } from 'org/configs/modules.config';

import {
  INSTORE_AD_OFFERS_GET_SUCCESS,
  INSTORE_AD_OFFERS_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: INSTORE_AD_OFFERS_GET_SUCCESS,
        failure: INSTORE_AD_OFFERS_GET_FAILURE,
      },
      params: {
        type: 'instore',
        active: 1,
      },
    },
    slick: {
      infinite: false,
      swipeToSlide: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1.1,
          },
        },
      ],
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
