import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { fetchPlacements } from 'core/actions/ocapi';
import keyCodes from 'core/utils/helpers/keyCodes';

import { selectMerchantOfferModalConfig, selectAssociatedDealOfferIds } from './selectors';

import { offerOverlayLinkClick } from './actions';

import './OfferOverlayLink.scss';

function OfferOverlayLink({
  merchantId, dealId, sectionId, offerId, preventOverlayShowing,
  merchantName, children, className,
}) {
  const dispatch = useDispatch();
  const offerIdRef = useRef();
  const isLoading = useRef();

  const config = useSelector(selectMerchantOfferModalConfig);
  const associatedDealOfferIds = useSelector(selectAssociatedDealOfferIds);

  useEffect(() => {
    if (!offerIdRef.current && !isEmpty(associatedDealOfferIds) && associatedDealOfferIds[dealId]) {
      offerIdRef.current = associatedDealOfferIds[dealId];
      dispatch(offerOverlayLinkClick({
        merchantId, offerId: offerIdRef.current, sectionId,
      }));
    }
  });

  const handleOfferClick = () => {
    if (preventOverlayShowing) {
      return;
    }

    if (offerId !== undefined || offerIdRef.current !== undefined) {
      dispatch(offerOverlayLinkClick({
        merchantId, offerId: offerId === undefined ? offerIdRef.current : offerId, sectionId,
      }));
    } else if (dealId !== undefined && !isLoading.current) {
      isLoading.current = true;
      const { api: apiConfig } = config;
      const { params, options } = apiConfig;
      params.deal_id = dealId;
      dispatch(fetchPlacements(params, options));
    }
  };

  const handleKeyPress = (event) => {
    const pressedKey = event.keyCode;
    if ((pressedKey === keyCodes.ENTER) || (pressedKey === keyCodes.SPACEBAR)) {
      event.target.click();
    }
  };

  const overlayClassName = `mn_offerOverlayLink ${className || ''}`;

  return (
    <div
      className={overlayClassName}
      data-merchant-name={merchantName}
      data-merchant-id={merchantId}
      data-offer-id={offerId}
      role="button"
      tabIndex="0"
      onClick={handleOfferClick}
      onKeyDown={handleKeyPress}
      data-test="offer-overlay-link"
      title={`Opens a dialog with offers and details for ${merchantName}`}
    >
      {children}
    </div>
  );
}

export default OfferOverlayLink;
