import {
  INSTORE_POST_LINK_TIPS_VIEW,
} from '../actions/types';

export const instoreHIWTypesActionEvents = {
  [INSTORE_POST_LINK_TIPS_VIEW]: ({ data }) => ({
      category: 'Impression',
      action: 'Viewed in-store post link tips pop',
      label: data,
    }),
};

export const instoreHIWTypesClickAndHoverEvents = [
  {
    selector: '.mn_instoreFirstLinkTips .mn_instoreFirstLinkButton',
    click: {
      category: 'In-store',
      action: 'Clicked first time post-link tips Got it CTA',
    },
  },
  {
    selector: '.mn_instoreSMSOptInReenterMobileNumberContainer',
    click: {
      category: 'In-store',
      action: 'Mobile number opt-in confirmation',
    },
  },
  {
    selector: '.mn_instoreFirstEnrollManageCard .mn_instoreFirstEnrollCTA',
    click: {
      category: 'In-store',
      action: 'Clicked first time card enroll tips CTA',
    },
  },
];
