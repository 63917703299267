import {
  select, takeEvery, put, call,
} from 'redux-saga/effects';
import { merge } from 'lodash';
import { isErrorResponse } from 'core/utils/apis/base';
import ocapi from 'core/utils/apis/ocapi';
import { selectOCAPIConfig } from 'core/selectors/services';
import { selectIsRavenCookied } from 'core/selectors/env';
import * as actions from 'core/actions/ocapi';
import { generateFailureAction, generateSuccessAction } from 'core/actions/apis';
import ocapiRequestor from 'core/utils/apis/ocapiRequestor';

function* makeOCAPIRequest({ caller, requestArgList, defaultValue }, action = {}) {
  const state = yield select(state => state);
  const response = yield call([ocapiRequestor, ocapiRequestor.getAPIResponse], {
    caller,
    requestArgList,
    shouldParseResponse: true,
    action,
    state,
  });

  if (!isErrorResponse(response)) {
    yield put(generateSuccessAction(action, response));
  } else {
    yield put(generateFailureAction(action, response, defaultValue));
  }
}

const takeEveryOCAPI = (actionType, caller, requestArgList, defaultValue) =>
  takeEvery(actionType, makeOCAPIRequest, { caller: caller.bind(ocapi), requestArgList, defaultValue });

export function* getOcapiInstance() {
  const ocapiConfig = yield select(selectOCAPIConfig);
  const isRavenCookied = yield select(selectIsRavenCookied);
  const config = merge({}, ocapiConfig, {
    headers: {
      ...isRavenCookied && { 'X-MNRaven': 1 },
    },
  });
  ocapi.setParams(config);
}

/**
 * Watch for any an all ocapi requests
 */
export default function* ocapiSaga() {
  yield getOcapiInstance();
  yield takeEveryOCAPI(actions.OCAPI_GET_CATEGORIES, ocapi.getCategories);
  yield takeEveryOCAPI(actions.OCAPI_GET_FLYOUT_CATEGORIES, ocapi.getFlyoutCategories);
  yield takeEveryOCAPI(actions.OCAPI_GET_CLICK_HISTORY, ocapi.getClickHistory);
  yield takeEveryOCAPI(actions.OCAPI_GET_CONTENT_PAGE_BY_ID, ocapi.getContentPageById, ['id'], {});
  yield takeEveryOCAPI(actions.OCAPI_GET_INSTORE_LOCATIONS, ocapi.getInstoreLocations);
  yield takeEveryOCAPI(actions.OCAPI_GET_INSTORE_OFFERS, ocapi.getInstoreOffers);
  yield takeEveryOCAPI(actions.OCAPI_GET_INSTORE_TRANSACTIONS, ocapi.getInstoreTransactions);
  yield takeEveryOCAPI(actions.OCAPI_GET_MEMBER_ACCOUNT_SUMMARY, ocapi.getMemberAccountSummary, undefined, {});
  yield takeEveryOCAPI(actions.OCAPI_GET_MEMBER_ORDERS, ocapi.getMemberOrders);
  yield takeEveryOCAPI(actions.OCAPI_GET_MEMBER_TRANSACTIONS, ocapi.getMemberTransactions);
  yield takeEveryOCAPI(actions.OCAPI_GET_MERCHANTS, ocapi.getMerchants);
  yield takeEveryOCAPI(actions.OCAPI_GET_MERCHANTS_BY_IDS, ocapi.getMerchantsByIds, ['ids']);
  yield takeEveryOCAPI(actions.OCAPI_GET_MERCHANT_BY_ID, ocapi.getMerchantById, ['id']);
  yield takeEveryOCAPI(actions.OCAPI_GET_SIMILAR_MERCHANTS_BY_ID, ocapi.getSimilarMerchantsById, ['id']);
  yield takeEveryOCAPI(actions.OCAPI_GET_MERCHANTS_ALL, ocapi.getMerchantsAll);
  yield takeEveryOCAPI(actions.OCAPI_GET_NAVIGATION, ocapi.getNavigation);
  yield takeEveryOCAPI(actions.OCAPI_GET_PLACEMENTS, ocapi.getPlacements);
  yield takeEveryOCAPI(actions.OCAPI_GET_PLACEMENTS_BY_ID, ocapi.getPlacementsById, ['id'], {});
  yield takeEveryOCAPI(actions.OCAPI_GET_SEARCH, ocapi.search);
  yield takeEveryOCAPI(actions.OCAPI_GET_SEARCH_PRODUCTS, ocapi.searchProducts);
  yield takeEveryOCAPI(actions.OCAPI_GET_SEARCH_PRODUCTS_BY_ID, ocapi.searchProductsById, ['id']);
  yield takeEveryOCAPI(actions.OCAPI_GET_SWEEPS, ocapi.getSweeps);
}
