import { shuffle } from 'lodash';

import config from '../configs';
import { INFO_BAR_MARKETING_GET_SUCCESS, INFO_BAR_MARKETING_GET_FAILURE } from '../actions/types';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  offers: [],
  responseDate: null,
};

const infoBarMarketingParser = (response = []) => {
  const merchantIndependentOffers = response
    .filter(offer => !offer.merchant)
    .map(offer => ({ ...offer, isInfoBarMarketing: true }));

  return merchantIndependentOffers;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INFO_BAR_MARKETING_GET_SUCCESS: {
      const { response, metadata } = action.payload;
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: shuffle(infoBarMarketingParser(response)),
        responseDate: metadata.date,
      };
    }
    case INFO_BAR_MARKETING_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: [],
        responseDate: null,
      };
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === INFO_BAR_MARKETING_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};
