import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import FavoriteIcon from 'core/modules/FavoriteIcon/FavoriteIcon';
import PremierMerchantRebate from 'core/modules/PremierMerchantRebate/PremierMerchantRebate';

import { selectUserIsIdentified } from 'core/selectors/user';

export function SynonymsMatches({
  config, synonymsMatches, keywords, merchantClickHandler,
}) {
  const isUserIdentified = useSelector(selectUserIsIdentified);
  const { showFavoriteIcon, rebateOptions, directMerchantName } = config;

  const handleMerchantClick = (event, name) => {
    merchantClickHandler(name, 'synonym');
  };

  const quickSearcMatchClassName =
  `mn_quickSearchMatch mn_quickSearchMerchantMatch ${isUserIdentified ? '' : 'mn_isAnonymous'}`;

  if (isEmpty(synonymsMatches)) {
    return null;
  }

  return (
    <div className="mn_quickSearchWrap mn_quickSearchSynonymsWrap">
      <div className="mn_quickSearchItems">
        {synonymsMatches.map((match) => {
        const { merchant } = match;
        const {
          id, name, href, rebate, showRebate, isDeactivated, isDirect,
        } = merchant;

          return (
            <div className="mn_quickSearchItem" key={`${name}-synonyms`}>
              <a
                href={`${href}&keywords=${keywords}`}
                className={quickSearcMatchClassName}
                onClick={(event) => handleMerchantClick(event, name)}
                title={`Opens merchant detail page for ${name}`}
              >
                <div className="mn_quickSearchMatchHighlight">
                  {showFavoriteIcon && <FavoriteIcon merchantId={id} merchantName={name} />}
                  <span className="mn_matchText" key={id}>{name}</span>
                  {(showRebate || isDeactivated) && <PremierMerchantRebate {...rebate} {...rebateOptions} />}
                </div>
                {isDirect && directMerchantName && <div className="mn_directMerchantPill">{directMerchantName}</div>}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><SynonymsMatches {...props} /></ComponentErrorBoundary>;
