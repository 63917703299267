import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchPlacements } from 'core/actions/ocapi';

import {
  selectHeaderHolidayImageConfig,
  selectHeaderHolidayImageIsLoaded,
  selectHeaderHolidayImageIsLoading,
  selectHeaderHolidayImagePlacement,
} from './selectors';

import './HeaderHolidayImage.scss';

function HeaderHolidayImage() {
  const config = useSelector(selectHeaderHolidayImageConfig);
  const isLoading = useSelector(selectHeaderHolidayImageIsLoaded);
  const isLoaded = useSelector(selectHeaderHolidayImageIsLoading);
  const imageUrl = useSelector(selectHeaderHolidayImagePlacement);
  const dispatch = useDispatch();

  useEffect(() => {
    const { shouldShowHolidayImage } = config;

    if (!shouldShowHolidayImage) {
      return;
    }

    if (!isLoaded && !isLoading) {
      const { api: { params, options } } = config;

      dispatch(fetchPlacements(params, options));
    }
  }, []);

  if (!imageUrl) return null;

  return (
    <div className="mn_holidayImage" aria-hidden="true">
      <div
        className="mn_imageWrap"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
    </div>
  );
}

export default HeaderHolidayImage;
