import { merge } from 'lodash';
import { formatDateWithFixedTimeZone } from './commonParsers/formatDateParser';

// Transaction States
const STATE_CONFIRMED = 'CLIENT_POSTED';
const STATE_REJECTED = 'CLIENT_HARD_REJECT';
const STATE_MATCHED = 'STATE_MATCHED';
const CLIENT_SOFT_REJECT = 'CLIENT_SOFT_REJECT';
const ACCRUAL_PENDING = 'ACCRUAL_PENDING';
const ACCRUAL_SENT = 'ACCRUAL_SENT';
const MEMBER_PROMOTION = 'MEMBER_PROMOTION';

const getTransactionStatus = ({ transactionState }) => {
  switch (transactionState) {
    case STATE_CONFIRMED:
      return 'Confirmed';
    case STATE_REJECTED:
      return 'Rejected';
    case STATE_MATCHED:
    case CLIENT_SOFT_REJECT:
    case ACCRUAL_PENDING:
    case ACCRUAL_SENT:
      return 'Pending';
    default:
      return null;
  }
};

export default response =>
  response.map(transaction =>
    merge(transaction, {
      isNegativeRebateAmount: transaction.rebateAmount < 0,
      status: getTransactionStatus(transaction),
      transactionDate: formatDateWithFixedTimeZone(transaction.transactionDate),
      carteraPostDate: formatDateWithFixedTimeZone(transaction.carteraPostDate),
      isRejected: transaction.transactionAmount < 0 || transaction.transactionState === STATE_REJECTED,
      isBonus: transaction.transactionType === MEMBER_PROMOTION,
    }));
