import React from 'react';

export function SynonymsMatches({ inquiryOrderStoreInputClickHandler }) {
  return (
    <div className="mn_quickSearchResults">
      <div
        className="mn_quickSearchFooter mn_noResults"
        data-test="quick-search-no-result"
      >
        {inquiryOrderStoreInputClickHandler
        ?
          <button className="mn_noMatch" onClick={inquiryOrderStoreInputClickHandler}>
            I cannot find my store name
          </button>
        :
          <span>- No matching stores -</span>
        }
      </div>
    </div>
  );
}

  export default SynonymsMatches;
