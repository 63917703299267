import { merge, isNil, isObject } from 'lodash';
import getPageName from './getPageName';

/**
 * @param {object} obj
 * @return {object}
 */
const removeNilProps = (obj) => {
  if (isObject(obj)) {
    Object.entries(obj).forEach(([key, value]) => {
      /* eslint-disable no-param-reassign */
      if (isNil(value)) {
        delete obj[key];
      } else if (isObject(value)) {
        obj[key] = removeNilProps(value);
      }
      /* eslint-enable no-param-reassign */
    });
  }
  return obj;
};

/**
 * @param {object} coreModuleConfig
 * @param {object} orgModuleConfig
 * @return {object}
 */
export default (coreModuleConfig, orgModuleConfig) => {
  const mergedConfig = merge({}, coreModuleConfig, orgModuleConfig);
  const defaultConfig = mergedConfig.defaults;
  const currentPageConfig = mergedConfig[getPageName()];
  const resultConfig = merge({}, defaultConfig, currentPageConfig);
  const strippedConfig = removeNilProps(resultConfig);
  return strippedConfig;
};
