import { getDisplayPageName } from 'core/utils/analytics/pageInfo';
import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { OFFER_MODAL_VIEW } from '../actions/types';

export const instoreOffersSectionActionEvents = {
  [OFFER_MODAL_VIEW]: ({ data }) => ({
      category: 'Impression',
      action: 'Opened merchant info lightbox',
      label: data,
    }),
};

export const instoreOffersSectionClickAndHoverEvents = [
  {
    selector: '.mn_instoreOfferRow .mn_instoreLinkButton',
    click: {
      category: 'In-store',
      action: 'Clicked offer hub row CTA',
    },
    label: element => {
      const instoreAdOffers = findAncestorByClassName(element, 'mn_instoreAdOffers') || {};
      const { viewType } = instoreAdOffers.dataset || {};

      return `${getDisplayPageName()} | ${viewType || 'logo'} version | ${element.dataset.gmidAndMerchantName}`;
    },
  },
  {
    selector: '.mn_instoreOfferRow',
    click: {
      category: 'In-store',
      action: element => {
        const linked = element.dataset.isLinked === 'true';
        return linked ? 'Clicked offer hub row already linked (outside of CTA/more info)' :
          'Clicked offer hub row more info';
      },
    },
    label: element => {
      const instoreAdOffers = findAncestorByClassName(element, 'mn_instoreAdOffers') || {};
      const { viewType } = instoreAdOffers.dataset || {};

      return `${getDisplayPageName()} | ${viewType || 'logo'} version | ${element.dataset.gmidAndMerchantName}`;
    },
  },
  {
    selector: '.mn_instoreOfferSection .mn_offerHubSortMenuTab .mn_offerHubSortMenuTabInActive',
    click: {
      category: 'In-store',
      action: 'Chose from sort dropdown',
    },
    label: element => element.dataset.sortType,
  },
];
