import { takeEvery, put } from 'redux-saga/effects';
import { INSTORE_MANAGE_CARDS_MODAL_TRIGGER }
from 'core/modules/Modal/components/InstoreModals/ManageCardsModal/actions/types';

import { INSTORE_MANAGE_CARDS_CLICK } from '../actions/types';

function* putInstoreManageCardsModalAction(action = {}) {
  const { data } = action;
  yield put({
    type: INSTORE_MANAGE_CARDS_MODAL_TRIGGER,
    data,
  });
}

export default function* instoreManageCardsOverlayClick() {
  yield takeEvery(INSTORE_MANAGE_CARDS_CLICK, putInstoreManageCardsModalAction);
}
