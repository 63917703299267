import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile, isTablet, isDesktop } from 'core/utils/helpers/browser';
import { throttle } from 'lodash';
import { triggerMediaQueriesUpdate } from './actions';
import {
  MQ_XS, MQ_S, MQ_M, MQ_L, MQ_XL, MQ_XXL,
} from './utils/mediaQueriesValues';
import { selectMediaQueries } from './selectors';

const checkIfMatchMQ = (props, mediaQueries) => {
  const { mobile, tablet, desktop } = props;

  const {
    activeMQ, isMobileMQ, isTabletMQ, isDesktopMQ,
  } = mediaQueries;

  const isMatchingDeviceMQ = mobile && isMobileMQ || tablet && isTabletMQ || desktop && isDesktopMQ;

  const isMatchingWidthMQ = [MQ_XS, MQ_S, MQ_M, MQ_L, MQ_XL, MQ_XXL].some((widthMQ) => (
    Object.prototype.hasOwnProperty.call(props, widthMQ.toLowerCase()) && activeMQ === widthMQ
  ));

  return isMatchingDeviceMQ || isMatchingWidthMQ;
};

const checkIfMatchDevice = ({ mobile, tablet, desktop }) => (
  mobile && isMobile || tablet && isTablet || desktop && isDesktop
);

export function MediaQueries({ children }) {
  const dispatch = useDispatch();

  const determineWindowWidth = () => {
    dispatch(triggerMediaQueriesUpdate(window?.innerWidth));
  };

  useEffect(() => {
    determineWindowWidth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const throttledDetermineWindowWidth = throttle(determineWindowWidth, 100);

    window.addEventListener('resize', throttledDetermineWindowWidth);

    return () => {
      window.removeEventListener('resize', throttledDetermineWindowWidth);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children || null;
}

export function ShowOnMQ(props) {
  const mediaQueries = useSelector(selectMediaQueries);
  const isMatchingMQ = checkIfMatchMQ(props, mediaQueries);

  return isMatchingMQ ? props.children : null;
}

export function HideOnMQ(props) {
  const mediaQueries = useSelector(selectMediaQueries);
  const isMatchingMQ = checkIfMatchMQ(props, mediaQueries);

  return isMatchingMQ ? null : props.children;
}

export function ShowOnDevice(props) {
  const isMatchingDevice = checkIfMatchDevice(props);

  return isMatchingDevice ? props.children : null;
}

export function HideOnDevice(props) {
  const isMatchingDevice = checkIfMatchDevice(props);

  return isMatchingDevice ? null : props.children;
}
