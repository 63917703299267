import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { productOverlayLink as orgConfig } from 'org/configs/modules.config';
import { PRODUCT_OVERLAY_LINK_GET_SUCCESS, PRODUCT_OVERLAY_LINK_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        limit: 15,
      },
      options: {
        success: PRODUCT_OVERLAY_LINK_GET_SUCCESS,
        failure: PRODUCT_OVERLAY_LINK_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
