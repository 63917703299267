import { takeEvery, put } from 'redux-saga/effects';

import { INSTORE_ALL_OFFERS_MAP_MODAL_TRIGGER } from
    'core/modules/Modal/components/InstoreAllOffersMapModal/actions/types';
import { NEAR_ME_MAP_CLICK } from '../actions/types';

function* putInstoreAllOffersMapModalAction(action = {}) {
  const { data } = action;
  yield put({
    type: INSTORE_ALL_OFFERS_MAP_MODAL_TRIGGER,
    data,
  });
}

export default function* instoreAllOffersMapOverlayClick() {
  yield takeEvery(NEAR_ME_MAP_CLICK, putInstoreAllOffersMapModalAction);
}
