import React, {
  useEffect, forwardRef, useRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import { useDebounce } from 'core/hooks/useDebounce';

import { fetchMerchants, fetchMerchantsAll } from 'core/actions/ocapi';
import { fetchGspStores } from 'core/actions/gsp';

import {
  selectInstoreIsEnabled, selectHasNoRewardsEnabled, selectBrandId,
} from 'core/selectors/app';
import { selectGspConfig } from 'core/selectors/services';

import QuickSearchMatches from './components/QuickSearchMatches';
import QuickSearchMatchesGSP from './components/QuickSearchMatchesGSP';

import {
  selectQuickSearchConfig,
  selectQuickSearchIsLoaded,
  selectQuickSearchIsLoading,
  selectQuickSearchMerchants,
} from './selectors';

import './QuickSearch.scss';

const QuickSearch = forwardRef((props, ref) => {
  const {
    keywords, merchantClickHandler, customClassName, footerText, config, inquiryOrderStoreInputClickHandler,
    sendDyEvent, searchBoxFormType, isPopUpView, shouldShowResultLinkOutside, getTitle,
  } = props;
  const gspConfig = useSelector(selectGspConfig);

  const shouldUseGsp = gspConfig.isEnabled && searchBoxFormType === 'Searchbox-Header';

  const instoreIsEnabled = useSelector(state => (!isNil(config && config.instoreIsEnabled)
    ? config.instoreIsEnabled : selectInstoreIsEnabled(state)));
  const hasNoRewardsEnabled = useSelector(selectHasNoRewardsEnabled);
  const localConfig = useSelector(state => selectQuickSearchConfig(state, config));
  const isLoading = useSelector(selectQuickSearchIsLoading);
  const isLoaded = useSelector(selectQuickSearchIsLoaded);
  const quickSearchMerchants = useSelector(selectQuickSearchMerchants);
  const dispatch = useDispatch();
  const quickSearchResultsRef = useRef();
  const brandId = useSelector(selectBrandId);
  const debouncedKeywords = useDebounce(keywords, 300); // GSP delay 300ms

  useImperativeHandle(ref, () => quickSearchResultsRef.current);

  const localMerchantClickHandler = (event) => {
    event.preventDefault();
    const { merchantId, merchantType, merchantName } = event.currentTarget.dataset;
    const redirectUrl = event.currentTarget.href;
    const merchant = quickSearchMerchants.find(({ id }) => id.toString() === merchantId.toString());

    merchantClickHandler({ merchant, merchantType, redirectUrl });
    sendDyEvent && sendDyEvent(merchantName);
  };

  // Fetch merchants list
  useEffect(() => {
    if (keywords.length > 0 && !isLoaded && !isLoading) {
      const { quickSearchMerchantsConfig } = localConfig;
      const { api: { params, options } } = quickSearchMerchantsConfig;

      const apiParams = {
        ...params,
        include_inactive: hasNoRewardsEnabled ? 1 : 0,
      };

      const merchantFetcher = instoreIsEnabled ? fetchMerchantsAll : fetchMerchants;

      dispatch(merchantFetcher(apiParams, options));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords]);

  // Fetch GSP suggested merchants
  useEffect(() => {
    const { minChars, quickSearchGspSuggestionsConfig } = localConfig;
    const { api: { params, options } } = quickSearchGspSuggestionsConfig;

    if (shouldUseGsp && keywords.length >= minChars) {
      const apiParams = {
        ...params,
        query: `subPartnerId: ${brandId} AND cf_text:"${encodeURI(keywords).replace(/%20/g, ' ')}"`,
      };
      dispatch(fetchGspStores(gspConfig.merchantsUrl, apiParams, options));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeywords]);

  if (!isLoaded || keywords.length < localConfig.minChars) {
    return null;
  }

  const componentProps = {
    config,
    keywords,
    quickSearchResultsRef,
    localMerchantClickHandler,
    inquiryOrderStoreInputClickHandler,
    footerText,
    shouldUseGsp,
    isPopUpView,
    shouldShowResultLinkOutside,
    getTitle,
  };

  return (
    <div className={customClassName || 'mn_quickSearch'} data-test="quick-search">
      { shouldUseGsp ?
        <QuickSearchMatchesGSP {...componentProps} /> : <QuickSearchMatches {...componentProps} />
      }
    </div>
  );
});

QuickSearch.propTypes = {
  config: PropTypes.shape({}),
  keywords: PropTypes.string,
  searchBoxFormType: PropTypes.string,
  merchantClickHandler: PropTypes.func,
  footerText: PropTypes.string,
  getTitle: PropTypes.func,
};

QuickSearch.defaultProps = {
  config: {},
  keywords: '',
  searchBoxFormType: '',
  merchantClickHandler: () => {},
  footerText: '',
  getTitle: () => {},
};

export default QuickSearch;
